<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import Layout from '@/views/layouts/main2'
import simplebar from 'simplebar-vue'
import service from '@/server/http_service'
import i18n from '@/i18n'
import DatePicker from 'vue2-datepicker'
import global_func from '@/global_function'
import moment from 'moment'
import Swal from 'sweetalert2'
import ModalMap from '@/components/modal/map'
import ModalCompany from '@/components/modal/company'
import ModalEstimate from '@/components/modal/all_estimate'
import ModalReview from '@/components/modal/review'
import ModalProof from '@/components/modal/proof_of_carrier'
/**
 * Starter page component
 */
export default {
  page: {
    title: 'แผนงานขนส่ง',
    meta: [{ name: 'description' }],
  },
  components: { Layout, simplebar, DatePicker, ModalMap, ModalCompany, ModalEstimate, ModalReview, ModalProof },
  data() {
    let lang = localStorage.getItem('account_lang')
    let user = localStorage.getItem('users')
    var lang_obj = i18n.t('plans_display')
    return {
      // bank - dev
      star_flag:false,
      bank_dev:0,
      //genaral
      account_lang: lang,
      account_data: JSON.parse(user),
      check_lang: true,
      lang: lang_obj,
      url_params_search_flag: true,
      // sort control
      search_plans_text: '', // รหัสแผนงาน สำหรับการค้นหา
      search_date_start: '',
      order_sel: '0',
      sort_by_status: '0',
      sort_by_tenderer_status: '0',
      sort_by_tenderer_name: '',
      // goods data
      dataGoodsType_master: [],
      dataGoodsType_data: [],
      // truck data
      dataTruckType: [],
      dataTruckTypeGroup: [],
      // plans data
      plans_data: [],
      plans_data_status: true,
      plans_row_data: [], // ข้อมูลที่แสดงในแถวแผนงาน
      plans_display_status: false,
      plan_data_selcet: [],
      // plan display
      activeArrow: [],
      active_tab: 0,
      service_provider_raw_data: [],
      plans_offer_raw_data: [],
      estimate_raw_data: [],
      service_provider_data: [
        // {
        //   provider_id: 2,
        //   provider_name: 'บริษัท เมอร์เล็กซ์ ทรานสปอร์ต จำกัด',
        //   provider_status_code: 2,
        //   provider_status_name: 'ไม่มีรถบริการในเวลาดังกล่าว',
        //   provider_tel: '081-256-6632',
        //   provider_email: 'admin2@admin2.com',
        //   star_score: '-',
        //   lover_score: '4.1',
        // },
      ],
      service_provider_data_master: [],
      offer_provider_data: [
        // {
        //   provider_id: 1,
        //   provider_name: 'KDR TRANSPORT COMPANY CO.LTD',
        //   provider_status_code: 1,
        //   provider_status_name: 'มีรถพร้อมบริการ',
        //   provider_tel: '02-751-2233',
        //   provider_email: 'admin1@admin1.com',
        //   star_score: '-',
        //   lover_score: '4.6',
        // },
      ],
      offer_provider_data_all: [],
      search_provider_text: '',
      search_tenderer_text: '',
      search_flag: false,
      search_tenderer_flag: false,
      modeSearch: '',
      estimate_data: [],
      baggage_raw_data: [],
      baggage_display_status: false,
      sort_by_provider_status: 0,
      reject_value: {
        '1': 'ราคาสูงเกินไป',
        '2': 'ประเภทรถไม่ถูกต้อง',
        '3': 'ใบเสนอราคาข้อมูลไม่ถูกต้อง',
        '4': 'อื่นๆ',
      },
      data_service_etc: [
        {
          service_etc_id: 1,
          name_th: 'พนักงานช่วยขนของ',
          name_en: 'พนักงานช่วยขนของ2',
          service_sel_status: '',
        },
        {
          service_etc_id: 2,
          name_th: 'พนักงานเพิ่มเติม',
          name_en: 'พนักงานเพิ่มเติม2',
          service_sel_status: '',
        },
        {
          service_etc_id: 3,
          name_th: 'บริการพาเลทรองพื้น',
          name_en: 'บริการพาเลทรองพื้น2',
          service_sel_status: '',
        },
        {
          service_etc_id: 4,
          name_th: 'อุปกรณ์ช่วยขนของ',
          name_en: 'อุปกรณ์ช่วยขนของ2',
          service_sel_status: '',
        },
        {
          service_etc_id: 5,
          name_th: 'อื่นๆ',
          name_en: 'อื่นๆ2',
          service_sel_status: '',
        },
      ],
      test:0,
      scrolled: false,
      scTimer: 0,
      scY: 0,
      activeScroll:false,
      subscribe_data : [],
    }
  },
  watch: {
    scrolled(val){
      // console.log(val)
    },
    search_date_start: {
      handler: function() {
        // console.log(this.search_date_start)
        this.search_plan('search_date')
      },
      deep: true,
    },
  },
  mounted() {
    window.addEventListener('wheel', this.bank_check_scroll)
    this.getDataGoodsType()
    this.getTruckType()
    this.getPlansData()
    this.getSubscribeData()
  },
  methods: {
    bank_check_scroll(){
      if(this.$refs.bankscrol != undefined) {
        if(this.$refs.bankscrol.scrollElement.scrollTop > 0){
          this.activeScroll = true
        }else{
          this.activeScroll = false
        }
      }
    },
    toTop() {
      this.$refs.bankscrol.scrollElement.scrollTo({ top: 0, behavior: 'smooth' })
    },
    updateStar(val, index){
      // console.log(val)
      var that = this
      let mode = 'unsub'
      if(val.star_flag == '0') {
        mode = 'sub'
      }
      if(this.service_provider_data[index].star_flag == '0') {
        this.service_provider_data[index].star_flag = '1'
      } else {
        this.service_provider_data[index].star_flag = '0'
      }

      if(this.offer_provider_data[index] != undefined) {
        if(this.offer_provider_data[index].star_flag == '0') {
          this.offer_provider_data[index].star_flag = '1'
        } else {
          this.offer_provider_data[index].star_flag = '0'
        }
      }

      service
        .postService('updateSubscribe', {
          account_data : this.account_data,
          provider_id : val.provider_id,
          mode : mode,
        })
        .then(rp => {
          if(rp.code == '200'){
            this.getSubscribeData()
            // setTimeout(() => {
            //   that.getProviderData()
            // }, 100)
          }
        })
    },
    update_favorite(option, index){
      if(this.plans_row_data[index].rawData.bookmark_flg == '0') {
        this.plans_row_data[index].rawData.bookmark_flg = '1'
      } else {
        this.plans_row_data[index].rawData.bookmark_flg = '0'
      }
      service
        .postService('setBookmarkPlan', {
          mode:'order',
          plan_code : option.rawData.id_order_tracking,
        })
        .then(rp => {
          if(rp.code == '200'){
            this.getPlansData()
          }
        })
    },
    // --- get data part ---
    getDataGoodsType() {
      // get ข้อมูล ประเภทสินค้า
      service.postService('getGoodsType').then(rp => {
        // console.log(rp)
        let data = rp.data
        for (let index = 0; index < data.length; index++) {
          var goods_type_sel = {
            goods_type_id: data[index]['goods_type_id'],
            goods_type_name_th: data[index]['goods_type_name_th'],
            goods_type_name_en: data[index]['goods_type_name_en'],
            goods_type_status: '',
          }
          var obj2 = JSON.stringify(goods_type_sel)
          var obj3 = JSON.parse(obj2)
          this.dataGoodsType_master.push(obj3) // for insert data to db
          this.dataGoodsType_data.push(obj3)
          if (this.account_lang != 'th') {
            this.check_lang = false
          }
        }
        // console.log(this.dataGoodsType_data)
      })
    },
    getTruckType() {
      // get ประเภทรถบรรทุก
      service.postService('getTruckType').then(rp => {
        // console.log(rp)
        this.dataTruckType = rp.data
        // console.log(this.dataTruckType)
      })
    },
    getPlansData(data_serach, func, id_order) {
      // get ข้อมูล แผนงานขนส่ง 
      var that = this
      var mode = func
      var order = id_order
      if (mode == '' || mode == undefined) {
        mode = ''
      }
      if (order == '' || order == undefined) {
        order = ''
      }
      service
        .postService('getPlansData', {
          mode: mode,
          data_serach: data_serach,
          date_serach: this.search_date_start,
          period: moment().format('YYYY-MM-DD HH:mm'),
          sort_by_order: this.order_sel,
          sort_by_status: this.sort_by_status,
          id_order: order,
          user_data: this.account_data,
        })
        .then(rp => {
          // console.log(rp)
          this.plans_data = rp.data
          setTimeout(() => {
            that.createRowOfPlans()
          }, 300)
          // console.log(this.plans_data)
        })
    },
    async getProviderData(mode) {
      var that = this
      this.search_flag = true
      let modeSearch = ''
      if(typeof mode !== 'undefined' ) {
        modeSearch = mode
      }
      service
        .postService('getProviderData', {
          user_data: this.account_data,
          plan_data_selcet: this.plan_data_selcet,
        })
        .then(rp => {
          // console.log('getProviderData', rp.data.provider_data)
          this.service_provider_raw_data = rp.data.provider_data
          this.plans_offer_raw_data = rp.data.plans_offer_data
          this.estimate_raw_data = rp.data.estimate
          this.baggage_raw_data = rp.data.baggage
          // console.log('baggage')
          // console.log(this.baggage_raw_data)
          if (this.baggage_raw_data.length > 0) {
            this.baggage_display_status = true
          } else {
            this.baggage_display_status = false
          }
          setTimeout(async () => {
            that.modeSearch = modeSearch
            await that.createRowProvider()
          }, 300)
        })
    },
    getSubscribeData() {
      service
        .postService('getSubscribeData', {
          account_data : this.account_data,
        })
        .then(rp => {
          // console.log(rp)
          if(rp.status == 'ok') {
            this.subscribe_data = []
            this.subscribe_data = rp.data
          }
          // console.log(this.subscribe_data)
        })
    },
    // --- แปรข้อมูลจาก raw data and แปรค่าข้อมูล ---
    getGoodsTypeName(goodsTypeId) {
      // นำ goods_type_id มาหาชื่อของประเภทสินค้า
      var data_return = ''
      if (goodsTypeId != null && goodsTypeId != '') {
        var res = goodsTypeId.split('|')
        var count_goods_type = 0
        // console.log(res)
        // console.log(this.dataGoodsType_data)
        for (let index2 = 0; index2 < res.length; index2++) {
          var goodsType_id = res[index2]
          if (count_goods_type < 2) {
            for (let index = 0; index < this.dataGoodsType_data.length; index++) {
              const element = this.dataGoodsType_data[index]
              if (element.goods_type_id == goodsType_id) {
                if (data_return != '') {
                  data_return += ', '
                  data_return += element.goods_type_name_th
                  if (!this.check_lang) {
                    data_return += element.goods_type_name_en
                  }
                } else {
                  data_return = element.goods_type_name_th
                  if (!this.check_lang) {
                    data_return += element.goods_type_name_en
                  }
                }
              }
            }
            count_goods_type++
          } else {
            data_return += ', ...'
          }
        }
      } else {
        data_return = '-'
      }
      // console.log(data_return)
      return data_return
    },
    getGoodsTypeNameFull(goodsTypeId) {
      // นำ goods_type_id มาหาชื่อของประเภทสินค้า แบบเต็ม
      var data_return = ''
      if (goodsTypeId != null && goodsTypeId != '') {
        var res = goodsTypeId.split('|')
        var count_goods_type = 0
        // console.log(res)
        // console.log(this.dataGoodsType_data)
        for (let index2 = 0; index2 < res.length; index2++) {
          var goodsType_id = res[index2]
          for (let index = 0; index < this.dataGoodsType_data.length; index++) {
            const element = this.dataGoodsType_data[index]
            if (element.goods_type_id == goodsType_id) {
              if (data_return != '') {
                data_return += ', '
                data_return += element.goods_type_name_th
                if (!this.check_lang) {
                  data_return += element.goods_type_name_en
                }
              } else {
                data_return = element.goods_type_name_th
                if (!this.check_lang) {
                  data_return += element.goods_type_name_en
                }
              }
            }
          }
        }
      } else {
        data_return = '-'
      }
      // console.log(data_return)
      return data_return
    },
    getPlanStatus(order_status) {
      // เช็คสถานะของ raw data ของ แผนงานนั้นๆ จาก order_status
      var data_return = ''
      if (order_status == '0') {
        data_return = 'รอเสนองาน' // งานใหม่
      } else if (order_status == '1') {
        data_return = 'รอเสนองาน'
      } else if (order_status == '2') {
        data_return = 'รอดำเนินการ'
      } else if (order_status == '3') {
        data_return = 'อยู่ระหว่างขนส่ง'
      } else if (order_status == '4') {
        data_return = 'ดำเนินการเสร็จแล้ว'
      } else if (order_status == '5') {
        data_return = 'ปิดงาน'
      }
      return data_return
    },
    getDateTime(datetime) {
      // แปลงเวลาเป็น format สำหรับแสดงบนเว็บ
      var ck1 = datetime !== null && datetime != null && datetime != undefined
      var data_return = this.lang.no_data_time
      if (ck1) {
        data_return = moment(datetime).format('dd DD/MM/YYYY HH:mm')
      }
      return data_return
    },
    getPickupTime(time1, time2) {
      var dateData = global_func.Between2Date(time1, time2)
      var diffTime = dateData.diffTime / 1000 / 60 / 60
      var date_return = ''
      if (diffTime > 23.9997222222 && diffTime < 24) {
        date_return = moment(time1).format('dd DD/MM/YYYY') + ' ' + '(ตลอดทั้งวัน)'
      } else {
        date_return = moment(time1).format('dd DD/MM/YYYY HH:mm') + ' - ' + moment(time2).format('dd DD/MM/YYYY HH:mm')
      }
      return date_return
    },
    getDifferencePrice(price_1, price_2) {
      var price = price_1 - price_2
      var cal_price = global_func.formatPrice(price)
      var mark = ''
      var data_return = ''
      if (price_1 < price_2) {
        mark = '+ '
      } else {
        mark = '- '
      }
      data_return = mark + cal_price
      return data_return
    },
    // --- sort data and binding part ---
    search_plan(func) {
      var mode = func
      var data_serach = ''
      // console.log(mode)
      if (mode == 'plan_code') {
        data_serach = this.search_plans_text
      } else if (mode == 'search_date') {
        data_serach = this.search_date_start
      }
      this.getPlansData(data_serach, mode)
    },
    findOfferProvider(provider_id) {
      var id = provider_id
      var array = this.plans_offer_raw_data
      // console.log(array)
      var plan_data = this.plan_data_selcet
      var flag = '0'
      if (array !== undefined && array !== null && array.length > 0) {
        for (let index = 0; index < array.length; index++) {
          const element = array[index]
          if (element.id_order_tracking == plan_data.rawData.id_order_tracking && element.provider_id == id) {
            flag = '1'
          }
        }
      } else {
        let es_raw_data = this.estimate_raw_data
        for (let index = 0; index < es_raw_data.length; index++) {
          const element2 = es_raw_data[index]
          if(index == 0) {
            if(element2.id_member == provider_id) {
              if(element2.accept_flg == 0 && element2.reject_flg == 0 && element2.cancel_plan_status == 0) {
                flag = '1'
              }
            }
          }
        }
      }
      return flag
    },
    findEstimateData() {
      // console.log(data)
      var ser_pro_data = this.service_provider_data
      var es_raw_data = this.estimate_raw_data
      if(es_raw_data == undefined) {
        es_raw_data = []
      }
      this.offer_provider_data = []
      this.offer_provider_data_all = []
      var estimate_data = []
      for (let index = 0; index < ser_pro_data.length; index++) {
        const element = ser_pro_data[index]
        var flag = 0
        for (let index2 = 0; index2 < es_raw_data.length; index2++) {
          const element2 = es_raw_data[index2]
          if (element2.id_member == element.provider_id) {
            estimate_data = element2
            var minus_flg = '0'
            if (this.plan_data_selcet.conv_price > estimate_data.price) {
              minus_flg = '1'
            }
            var data_push = {
              provider_id: element.provider_id,
              provider_name: element.provider_name,
              provider_status_code: this.findProviderStatus(element),
              provider_status_name: element.provider_status_name,
              provider_tel: element.provider_tel,
              provider_email: element.provider_email,
              provider_plan_action_code: element.provider_plan_action_code,
              provider_profile_pic: element.provider_profile_pic,
              star_score: element.star_score,
              lover_score: element.lover_score,
              active_status: element.active_status,
              diff_price: this.getDifferencePrice(this.plan_data_selcet.conv_price, estimate_data.price),
              minus_price_flg: minus_flg,
              estimate_data: estimate_data,
              star_flag : element.star_flag,
            }
            // console.log(data_push)
            if (data_push != undefined && flag == 0) {
              this.offer_provider_data.push(data_push)
              flag++
              this.offer_provider_data_all.push(data_push)
            }
          }
        }
        if (this.baggage_raw_data.length > 0) {
          this.baggage_display_status = true
        } else {
          this.baggage_display_status = false
        }
        if(this.modeSearch == 'provider') {
          this.bindingProviderStatus()
        } else if(this.modeSearch == 'tenderer') {
          this.bindingTendererStatus()
        }
        this.search_flag = false
      }
      // console.log(this.offer_provider_data)
    },
    findTruckTypeName(truck_type_id) {
      var truck_type_name = ''
      for (let index = 0; index < this.dataTruckType.length; index++) {
        const element = this.dataTruckType[index]
        if (truck_type_id == element.id_model) {
          if (truck_type_name != '') {
            truck_type_name += ', '
          }
          if (this.account_lang == 'en') {
            truck_type_name += element.name_model_en
          } else {
            truck_type_name += element.name_model_th
          }
        }
      }
      return truck_type_name
    },
    findTruckTypeName2(truck_type_id) {
      var truck_type_name = ''
      for (let index = 0; index < truck_type_id.length; index++) {
        const element = truck_type_id[index]
        if(index <= 1) {
          for (let index2 = 0; index2 < this.dataTruckType.length; index2++) {
            const element2 = this.dataTruckType[index2]
            if (element == element2.id_model) {
              if (truck_type_name != '') {
                truck_type_name += ', '
              }
              if (this.account_lang == 'en') {
                truck_type_name += element2.name_model_en
              } else {
                truck_type_name += element2.name_model_th
              }
            }
          }
        }
      }
      return truck_type_name
    },
    findService_other(service_other_data) {
      var id_obj = service_other_data.other_services.split('|')
      var date_return = ''
      for (let index = 0; index < this.data_service_etc.length; index++) {
        const element = this.data_service_etc[index]
        // console.log('บริการเสริม')
        // console.log(element)
        for (let index2 = 0; index2 < id_obj.length; index2++) {
          const id = id_obj[index2]
          if (id == element.service_etc_id) {
            if (date_return != '') {
              date_return += ', '
            }
            if (this.account_lang == 'en') {
              date_return += element.name_en
            } else {
              date_return += element.name_th
            }
            // if service = อื่นๆ
            if (id == '5') {
              date_return += ' (' + service_other_data.other_services_detail + ')'
            }
          }
        }
      }
      return date_return
    },
    findProviderStatus(provider_raw_data) {
      // console.log('provider_data')
      // console.log(provider_raw_data)
      let provider_data = []
      if(provider_raw_data.provider_data !== undefined) {
        provider_data = provider_raw_data.provider_data
      } else {
        provider_data = provider_raw_data
      }
      let id_member = provider_data.id_member
      let plans_data = provider_data.plans_data
      let reject_flg = provider_data.reject_flg
      let action_code = this.findOfferProvider(id_member)
      let data_return = '1'
      if(typeof plans_data !== 'undefined') {
        // console.log('plans_data')
        // console.log(plans_data)
        if(plans_data.length > 0) {
          data_return = '2'
        } else {
          data_return = '1'
        }
      }
      // console.log(action_code)
      if(action_code == '1') {
        data_return = '3'
      }
      if(reject_flg == '1') {
        data_return = '4'
      }
      return data_return
    },
    async bindingProviderStatus() {
      let that = this
      let sort_by_provider_status = that.sort_by_provider_status
      let search_provider_text = that.search_provider_text
      let data_provider_master = that.service_provider_data_master
      let data_array = []
      for (let index = 0; index < data_provider_master.length; index++) {
        const element = data_provider_master[index]
        // console.log(element)
        if(sort_by_provider_status != '0') {
          if(search_provider_text != '') {
            let find_string = global_func.findString(element.provider_name, search_provider_text)
            if(element.provider_status_code == sort_by_provider_status && find_string) {
              data_array.push(element)
            }
          } else {
            if(element.provider_status_code == sort_by_provider_status) {
              data_array.push(element)
            }
          }
        } else {
          if(search_provider_text != '') {
            let find_string = global_func.findString(element.provider_name, search_provider_text)
            if(find_string) {
              data_array.push(element)
            }
          } else {
            data_array.push(element)
          }
        }
      }
      that.service_provider_data = data_array
      this.search_flag = false
    },
    timeout_search_provider() {
      var that = this
      // console.log('ok');
      this.search_flag = true
      clearTimeout(that.debounce)
      this.debounce = setTimeout(function() {
        that.getProviderData('provider')
      }, 500)
    },
    async bindingTendererStatus() {
      let status = this.sort_by_tenderer_status
      let text = this.sort_by_tenderer_name
      let data_tenderer_master = this.offer_provider_data_all
      let data_array = []
      for (let index = 0; index < data_tenderer_master.length; index++) {
        const element = data_tenderer_master[index]
        // console.log(element)
        if(status != '0') {
          if(text != '') {
            let find_string = global_func.findString(element.provider_name, text)
            if(element.provider_status_code == status && find_string) {
              data_array.push(element)
            }
          } else {
            if(element.provider_status_code == status) {
              data_array.push(element)
            }
          }
        } else {
          if(text != '') {
            let find_string = global_func.findString(element.provider_name, text)
            if(find_string) {
              data_array.push(element)
            }
          } else {
            data_array.push(element)
          }
        }
      }
      this.offer_provider_data = data_array
      this.search_flag = false
    },
    timeout_search_tenderer() {
      var that = this
      // console.log('ok');
      this.search_flag = true
      clearTimeout(that.debounce)
      this.debounce = setTimeout(function() {
        that.getProviderData('tenderer')
      }, 500)
    },
    timeout_search_plans() {
      var that = this
      // console.log('ok');
      clearTimeout(that.debounce)
      this.debounce = setTimeout(function() {
        that.search_plan('plan_code')
      }, 500)
    },
    findStarFlag(id_member) {
      let subscribe_data = this.subscribe_data
      let flag = '0'
      for (let index = 0; index < subscribe_data.length; index++) {
        const element = subscribe_data[index]
        if(element.provider_id == id_member) {
          flag = '1'
        }
      }
      return flag
    },
    bindingProviderStar(data) {
      let provider_data = data
      let provider_data_1 = []
      let provider_data_2 = []
      let provider_data_return = []

      // star first
      for (let index = 0; index < provider_data.length; index++) {
        const element = provider_data[index]
        if(element.star_flag == '1') {
          provider_data_1.push(element)
        } else {
          provider_data_2.push(element)
        }
      }

      for (let index2 = 0; index2 < provider_data_1.length; index2++) {
        const element = provider_data_1[index2]
        provider_data_return.push(element)
      }

      for (let index3 = 0; index3 < provider_data_2.length; index3++) {
        const element = provider_data_2[index3]
        provider_data_return.push(element)
      }

      return provider_data_return
      
    },
    // --- plan display part ---
    createRowOfPlans() {
      var ck1 = this.dataGoodsType_data.length > 0
      var ck2 = this.dataTruckType.length > 0
      var ck3 = this.plans_data.length > 0
      this.plans_row_data = []
      if (ck1 && ck2 && ck3) {
        var rowPlanData = {}
        for (let index = 0; index < this.plans_data.length; index++) {
          const row = this.plans_data[index]
          // console.log(row)
          let pickup_time = this.getPickupTime(row.pickup_time_1, row.pickup_time_2)
          let vehicle_type_obj = row.vehicle_type_id.split('|')
          var truck_type_name = this.findTruckTypeName2(vehicle_type_obj)
          var truck_type_group = row.vehicle_type_group_id
          if (vehicle_type_obj.length > 1) {
            truck_type_name = truck_type_name + ', ...'
          }
          rowPlanData = {
            truck_type_name: truck_type_name,
            truck_type_group: truck_type_group,
            goods_type_name: this.getGoodsTypeName(row.goods_type_id),
            goods_type_name_full: this.getGoodsTypeNameFull(row.goods_type_id),
            status_name: this.getPlanStatus(row.order_status),
            status_id: row.order_status,
            pickup_time: pickup_time,
            conv_price: global_func.formatPrice(row.expected_price),
            rawData: row,
          }
          // console.log(rowPlanData)
          this.plans_row_data.push(rowPlanData)
          this.plans_data_status = true
        }
        var urlParams = new URLSearchParams(window.location.search)
        if(urlParams.get('data') != null && this.url_params_search_flag){
          this.url_params_search_flag = false
          const found = this.plans_row_data.find(element => element.rawData.id_order_tracking == urlParams.get('data'))
          this.activePlansDisplay(found)
        }
        // console.log(this.plans_row_data)
      } else {
        this.plans_data_status = false
      }
    },
    createDetailOfPlans() {
      var planData = {}
      for (let index = 0; index < this.plans_data.length; index++) {
        const row = this.plans_data[index]
        // console.log(row)
        let pickup_time = this.getPickupTime(row.pickup_time_1, row.pickup_time_2)
        let vehicle_type_obj = row.vehicle_type_id.split('|')
        var truck_type_name = this.dataTruckType[vehicle_type_obj[0]].name_model_th
        var truck_type_group = this.dataTruckType[vehicle_type_obj[0]].id_group
        if (vehicle_type_obj.length > 1) {
          truck_type_name = truck_type_name + ', ...'
        }
        planData = {
          truck_type_name: truck_type_name,
          truck_type_group: truck_type_group,
          goods_type_name: this.getGoodsTypeName(row.goods_type_id),
          goods_type_name_full: this.getGoodsTypeNameFull(row.goods_type_id),
          status_name: this.getPlanStatus(row.order_status),
          status_id: row.order_status,
          pickup_time: pickup_time,
          conv_price: global_func.formatPrice(row.expected_price),
          rawData: row,
        }
        // console.log(planData)
        this.plan_data_selcet = {}
        this.plan_data_selcet = planData
        this.getPlansData()
      }
    },
    createRowProvider() {
      var data = this.service_provider_raw_data
      this.service_provider_data = []
      this.offer_provider_data = []
      let provider_data = []
      for (let index = 0; index < data.length; index++) {
        const element = data[index]
        var data_push = {
          provider_id: element.id_member,
          provider_name: element.mover_name,
          provider_status_code: this.findProviderStatus(element),
          provider_status_name: '',
          provider_tel: element.mover_tel,
          provider_email: element.mailaddr,
          provider_plan_action_code: this.findOfferProvider(element.id_member),
          provider_profile_pic: element.provider_profile_pic,
          star_score: element.user_score.star_score,
          lover_score: element.user_score.lover_score,
          active_status: false,
          provider_data: element,
          star_flag : this.findStarFlag(element.id_member),
        }
        // console.log(data_push)
        provider_data.push(data_push)
      }
      this.service_provider_data = this.bindingProviderStar(provider_data)
      // console.log(this.service_provider_data)
      this.service_provider_data_master = this.service_provider_data
      let that = this
      setTimeout(() => {
        that.findEstimateData()
      }, 100)
    },
    // action method
    activePlansDisplay(planData) {
      // console.log('activePlansDisplay',planData)

      this.plan_data_selcet = planData
      // console.log('plan data select')
      // console.log(this.plan_data_selcet)
      if (this.plans_display_status) {
        this.plans_display_status = false
      } else {
        this.plans_display_status = true
        this.getProviderData()
      }
    },
    cilck_provider(row_index, type_provider) {
      // console.log(row_index)
      // console.log(type_provider)
      let index = row_index
      if (type_provider == 'service') {
        if (this.service_provider_data[index].active_status) {
          this.service_provider_data[index].active_status = false
        } else {
          this.service_provider_data[index].active_status = true
        }
      } else {
        if (this.offer_provider_data[index].active_status) {
          this.offer_provider_data[index].active_status = false
        } else {
          this.offer_provider_data[index].active_status = true
        }
      }
    },
    provider_action(row_data) {
      var data = row_data
      // console.log(data)
      for (let index = 0; index < this.service_provider_data_master.length; index++) {
        const element = this.service_provider_data_master[index]
        if (element.provider_id == data.provider_id) {
          if (this.service_provider_data_master[index].provider_plan_action_code == '1') {
            this.service_provider_data_master[index].provider_plan_action_code = '0'
            this.service_provider_data_master[index].provider_status_code = this.findProviderStatus(this.service_provider_data_master[index].provider_data)
          } else {
            this.service_provider_data_master[index].provider_plan_action_code = '1'
            this.service_provider_data_master[index].provider_status_code = '3'
          }
        }
      }
    },
    bt_detail(data) {
      var param_send = { provider_id: data.provider_id }
      this.$refs.modalCompany.setProviderData(param_send, this.$refs.modalReview)
      // this.$refs.modalReview.setProviderData(param_send)
      // that.$bvModal.show('modal_company_detail')
    },
    activeSelectTruckType() {
      this.$bvModal.show('modal_select_truckType')
    },
    getTruckType_select: function(dataTruckType, dataTruckTypeGroup) {
      this.dataTruckType = dataTruckType
      this.dataTruckTypeGroup = dataTruckTypeGroup
    },
    openFileTab(param) {
      if (param.filename != null && param.filename != undefined) {
        let file_data = {
          filename: param.filename,
          title: param.title,
          filetype: param.filetype,
        }
        service
          .postService('readFileDataService', {
            fileData: file_data,
          })
          .then(rp => {
            // console.log(rp)
            if(rp.data != '') {
              //var file = require('/../file_upload_dtc_backhaul/images/offer_file/2021_8_6_11_24_36_105.pdf') //pdf jpg
              // var file = require('/../file_upload_dtc_backhaul/images/offer_file/' + param.filename) //pdf jpg
              var file = rp.data
              var pdfWindow = window.open('')
              pdfWindow.document.write(
                '<iframe width=\'100%\' height=\'100%\' src=' + file + '></iframe>',
              )
            }
          })
      }
    },
    openAllEsitmate(option) {
      var data = option
      // console.log(this.offer_provider_data_all)
      // modal_all_estimate
      // console.log(this.$refs)
      // console.log(this.$refs.modal_all_estimate)
      this.$refs.modal_all_estimate.active(data, this.dataTruckType)
    },
    routersClick(link,option) {
      // eslint-disable-next-line no-undef
      this.$router.push({ path: link + `/?data=${this.plan_data_selcet.rawData.id_order_tracking}` })      
    },
    routersClick2(link) {
      // eslint-disable-next-line no-undef
      this.$router.push({ path: link })
    },
    // send and delete data
    sendPlanOffer(row_data, plan_data) {
      var data1 = row_data
      var data2 = plan_data
      var that = this
      // console.log(data2)
      service
        .postService('sendPlanOffer', {
          provider_data: data1,
          plan_data: data2,
        })
        .then(rp => {
          // console.log('sendPlanOffer')
          // console.log(rp)
          if(rp.status == 'ok') {
            this.plans_offer_raw_data = rp.data
          }
          global_func.alertMessage('ยื่นข้อเสนอเรียบร้อย', '', 'success')
          setTimeout(function() {
            that.provider_action(row_data)
          }, 1500)
        })
    },
    sendAcceptOffer(estimate) {
      var that = this
      var id_order = estimate.estimate_data.id_order
      service
        .postService('sendAcceptOffer', {
          estimate_data: estimate,
          account_data: that.account_data,
        })
        .then(rp => {
          // console.log(rp)
          if(rp.code == '200') {
            global_func.alertMessage(that.lang.alert.accept, '', 'success')
            this.plan_data_selcet.rawData.order_status = '2'
            this.plan_data_selcet.status_id = '2'
            this.plan_data_selcet.status_name = this.getPlanStatus('2')
            setTimeout(function() {
              that.getPlansData()
            // that.createDetailOfPlans()
            }, 1000)
            setTimeout(function() {
              that.getProviderData()
            }, 1500)
          } else {
            Swal.fire({
              icon: 'error',
              title: that.lang.alert.failed,
              confirmButtonText: that.lang.btn.close,
            })
          }
        })
    },
    sendRejectOffer(estimate, reject_comment) {
      var that = this
      service
        .postService('sendRejectOffer', {
          estimate_data: estimate,
          reject_comment: reject_comment,
          account_data: this.account_data,
        })
        .then(rp => {
          // console.log(rp)
          if(rp.code == '200') {
            global_func.alertMessage(that.lang.alert.reject, '', 'success')
            setTimeout(function() {
              that.getProviderData()
            }, 1500)
          } else {
            Swal.fire({
              icon: 'error',
              title: that.lang.alert.failed,
              confirmButtonText: that.lang.btn.close,
            })
          }
        })
    },
    sendDeleteOffer(estimate) {
      var that = this
      service
        .postService('sendDeleteOffer', {
          estimate_data: estimate,
          account_data: this.account_data,
        })
        .then(rp => {
          // console.log(rp)
          if(rp.code == '200') {
            global_func.alertMessage(that.lang.alert.del_offer, '', 'success')
            setTimeout(function() {
              that.getProviderData('tenderer')
            }, 1500)
          } else {
            Swal.fire({
              icon: 'error',
              title: that.lang.alert.failed,
              confirmButtonText: that.lang.btn.close,
            })
          }
        })
    },
    sendFinPlan(estimate) {
      // console.log('test ja')
      // console.log(estimate)
      let data = {
        estimate_data : estimate,
      }
      var that = this
      service
        .postService('sendFinPlan', {
          estimate_data: data,
          account_data: that.account_data,
        })
        .then(rp => {
          // console.log(rp)
          if(rp.code == '200') {
            service
              .postService('deleteMatchCar', {
                id_carrier: estimate.id_carrier,
              })
              // eslint-disable-next-line no-unused-vars
              .then(rp => {
                if(rp.code == '200') {
                  global_func.alertMessage(that.lang.alert.fin_plan, '', 'success')
                  this.plan_data_selcet.rawData.order_status = '4'
                  this.plan_data_selcet.status_id = '4'
                  this.plan_data_selcet.status_name = this.getPlanStatus('4')
                  setTimeout(function() {
                    that.getPlansData()
                  // that.createDetailOfPlans()
                  }, 1000)
                  setTimeout(function() {
                    that.getProviderData()
                  }, 1500)
                } else {
                  Swal.fire({
                    icon: 'error',
                    title: that.lang.alert.del_truck_failed,
                    confirmButtonText: that.lang.btn.close,
                  })
                }
              })
          } else {
            Swal.fire({
              icon: 'error',
              title: that.lang.alert.failed,
              confirmButtonText: that.lang.btn.close,
            })
          }
          
        })
    },
    sendCancelPlan(estimate) {
      var that = this
      var id_order = estimate.estimate_data.id_order
      service
        .postService('sendCancelPlan', {
          estimate_data: estimate,
          account_data: this.account_data,
        })
        .then(rp => {
          // console.log(rp)
          if(rp.code == '200') {
            global_func.alertMessage(that.lang.alert.cancel_plan, '', 'success')
            this.plan_data_selcet.rawData.order_status = '1'
            this.plan_data_selcet.status_id = '1'
            this.plan_data_selcet.status_name = this.getPlanStatus('1')
            setTimeout(function() {
              that.getPlansData()
              // that.createDetailOfPlans()
            }, 1000)
            setTimeout(function() {
              that.getProviderData()
            }, 1500)

          } else {
            Swal.fire({
              icon: 'error',
              title: that.lang.alert.failed,
              confirmButtonText: that.lang.btn.close,
            })
          }
        })
    },
    delPlanOffer(row_data, plan_data) {
      var data1 = row_data
      var data2 = plan_data
      var that = this
      service
        .postService('delPlanOffer', {
          provider_data: data1,
          plan_data: data2,
        })
        .then(rp => {
          // console.log(rp)
          if(rp.status == 'ok') {
            this.plans_offer_raw_data = rp.data
          }
          global_func.alertMessage('ยกเลิกการยื่นข้อเสนอเรียบร้อย', '', 'success')
          setTimeout(function() {
            that.provider_action(row_data)
          }, 1500)
        })
    },
    delPlanData(plan_data) {
      var planData = plan_data
      var that = this
      // console.log('test')
      // console.log(planData)
      service
        .postService('delPlanData', {
          plan_data: planData,
        })
        .then(rp => {
          // console.log(rp)
          if(rp.code == '200') {
            global_func.alertMessage(that.lang.alert.del_plan, '', 'success')
            setTimeout(function() {
              that.activePlansDisplay()
              that.getPlansData()
              // that.delPlanDataFromRowDisplay(planData)
            }, 1500)
          } else {
            Swal.fire({
              icon: 'error',
              title: that.lang.alert.failed,
              confirmButtonText: that.lang.btn.close,
            })
          }
        })
    },
    delPlanDataFromRowDisplay(planData) {
      var plan_data_sel_del = planData
      for (let index = 0; index < this.plans_row_data.length; index++) {
        const element = this.plans_row_data[index]
        if (element.rawData.id_order == plan_data_sel_del.id_order) {
          this.plans_row_data[index] = ''
        }
      }
    },
    // ask confirm part
    askSendAlert(row_data, plan_data) {
      Swal.fire({
        title: 'คุณยืนยันที่จะ “เสนองาน” ใช่หรือไม่',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        allowOutsideClick: true,
        confirmButtonColor: '#34c38f',
        // cancelButtonColor: '#FFFFFF',
        // cancelButtonTextColor: '#343C48',
        confirmButtonText: 'ใช่ ฉันยืนยัน',
        cancelButtonText: 'ยกเลิก',
      }).then(result => {
        if (result.value) {
          // console.log('plan_data')
          // console.log(plan_data)
          this.sendPlanOffer(row_data, plan_data)
        } else {
          return false
        }
      })
    },
    askDelPlan(plan_data) {
      Swal.fire({
        title: 'คุณยืนยันที่จะ “ลบแผนงาน” ใช่หรือไม่',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        allowOutsideClick: true,
        confirmButtonColor: '#34c38f',
        // cancelButtonColor: '#FFFFFF',
        // cancelButtonTextColor: '#343C48',
        confirmButtonText: 'ใช่ ฉันยืนยัน',
        cancelButtonText: 'ยกเลิก',
      }).then(result => {
        if (result.value) {
          this.delPlanData(plan_data)
        } else {
          return false
        }
      })
    },
    askDelProviderOffer(row_data, plan_data) {
      Swal.fire({
        title: 'คุณยืนยันที่จะ ยกเลิกการเสนองาน ใช่หรือไม่',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        allowOutsideClick: true,
        confirmButtonColor: '#34c38f',
        // cancelButtonColor: '#FFFFFF',
        // cancelButtonTextColor: '#343C48',
        confirmButtonText: 'ใช่ ฉันยืนยัน',
        cancelButtonText: 'ยกเลิก',
      }).then(result => {
        if (result.value) {
          this.delPlanOffer(row_data, plan_data)
        } else {
          return false
        }
      })
    },
    askAcceptOffer(option) {
      Swal.fire({
        title: 'คุณยืนยันที่จะ' + '<br />' + '“ยอมรับ/เลือกผู้ให้บริการนี้”' + '<br />' + 'ใช่หรือไม่',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        allowOutsideClick: true,
        confirmButtonColor: '#34c38f',
        // cancelButtonColor: '#FFFFFF',
        // cancelButtonTextColor: '#343C48',
        confirmButtonText: 'ใช่ ฉันยืนยัน',
        cancelButtonText: 'ยกเลิก',
      }).then(result => {
        if (result.value) {
          this.sendAcceptOffer(option)
        } else {
          return false
        }
      })
    },
    askRejectOffer(option) {
      Swal.fire({
        // title: 'คุณยืนยันที่จะ' + '<br />' + '“ยื่นข้อเสนอใหม่”' + '<br />' + 'ใช่หรือไม่',
        title: 'ยื่นข้อเสนอใหม่',
        text: '',
        icon: 'warning',
        input: 'select',
        inputLabel: 'โปรดระบุสาเหตุที่ต้องการ ”ยื่นข้อเสนอใหม่”',
        inputPlaceholder: 'เลือกสาเหตุ',
        inputOptions: {
          '1': 'ราคาสูงเกินไป',
          '2': 'ประเภทรถไม่ถูกต้อง',
          '3': 'ใบเสนอราคาข้อมูลไม่ถูกต้อง',
          '4': 'อื่นๆ',
        },
        showCancelButton: true,
        allowOutsideClick: true,
        confirmButtonColor: '#34c38f',
        // cancelButtonColor: '#FFFFFF',
        // cancelButtonTextColor: '#343C48',
        confirmButtonText: 'ใช่ ฉันยืนยัน',
        cancelButtonText: 'ยกเลิก',
      }).then(result => {
        if (result.value) {
          // console.log(result)
          if (result.value == '4') {
            Swal.fire({
              title: 'สาเหตุการยื่นข้อเสนอใหม่',
              input: 'text',
              icon: 'warning',
              inputPlaceholder: 'ระบุสาเหตุของท่าน',
              inputAttributes: {
                maxlength: 30,
                autocapitalize: 'off',
                autocorrect: 'off',
              },
            }).then(result => {
              if (result.value) {
                this.sendRejectOffer(option, result.value)
              } else {
                return false
              }
            })
          } else {
            this.sendRejectOffer(option, this.reject_value[result.value])
          }
        } else {
          return false
        }
      })
    },
    askDeleteOffer(option) {
      Swal.fire({
        title: 'คุณยืนยันที่จะ' + '<br />' + '“ไม่สนใจข้อเสนอนี้”' + '<br />' + 'ใช่หรือไม่',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        allowOutsideClick: true,
        confirmButtonColor: '#34c38f',
        // cancelButtonColor: '#FFFFFF',
        // cancelButtonTextColor: '#343C48',
        confirmButtonText: 'ใช่ ฉันยืนยัน',
        cancelButtonText: 'ยกเลิก',
      }).then(result => {
        if (result.value) {
          this.sendDeleteOffer(option)
        } else {
          return false
        }
      })
    },
    askFinPlan(option) {
      Swal.fire({
        title: 'คุณยืนยันที่จะ' + '<br />' + 'จบแผนงานนี้' + '<br />' + 'ใช่หรือไม่',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        allowOutsideClick: true,
        confirmButtonColor: '#34c38f',
        // cancelButtonColor: '#FFFFFF',
        // cancelButtonTextColor: '#343C48',
        confirmButtonText: 'ใช่ ฉันยืนยัน',
        cancelButtonText: 'ยกเลิก',
      }).then(result => {
        if (result.value) {
          // this.sendFinPlan(option)
          this.$bvModal.hide('modal_proof')
          this.openReviewModal(option)
        } else {
          return false
        }
      })
    },
    askCancelPlan(option) {
      Swal.fire({
        title: 'คุณยืนยันที่จะ' + '<br />' + 'ยกเลิกงานนี้' + '<br />' + 'ใช่หรือไม่' + '<br /><br />' + '*** การยกเลิกงานอาจจะมีค่าปรับเกิดขึ้น<br />ขึ้นอยู่กับผู้ให้บริการขนส่ง',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        allowOutsideClick: true,
        confirmButtonColor: '#34c38f',
        // cancelButtonColor: '#FFFFFF',
        // cancelButtonTextColor: '#343C48',
        confirmButtonText: 'ใช่ ฉันยืนยัน',
        cancelButtonText: 'ยกเลิก',
      }).then(result => {
        if (result.value) {
          this.sendCancelPlan(option)
        } else {
          return false
        }
      })
    },
    openReviewModal(param_send) {
      this.$refs.modalReview.setProviderData(param_send)
    },
    openProofModal(param_send) {
      this.$refs.modalProof.getProofOfCarrier(param_send)
    },
  },
}
</script>

<template>
  <Layout>
    <b-row>
      <b-col>
        <transition name="bounce" enter-active-class="fadeInLeft" leave-active-class="fadeOutLeft">
          <div v-if="plans_display_status" class="col-main-menu summary-of-plan" :class="{ active: plans_display_status }">
            <div class="card" style="">
              <b-row>
                <b-col>
                  <div class="card-body" style="">
                    <div style="padding: 10px 10px 0 10px;" class="font-size-16">
                      <b-row class="col-margin-bot-1">
                        <b-col lg="3">
                          <span class="d-flex justify-content-left btn_back_blue" @click="activePlansDisplay()"><i class="ri-arrow-left-line" style="margin-right:5px;" />ย้อนกลับ</span>
                        </b-col>
                      </b-row>
                      <b-row class="col-margin-bot-1">
                        <b-col lg="11">
                          <span style="font-size:20px;">
                            <b>{{ lang.plans_detail_display.title }}</b>
                          </span>
                        </b-col>
                        <b-col v-if="plan_data_selcet.rawData.order_status == '1' || plan_data_selcet.rawData.order_status == '0'" lg="1" class="d-flex align-items-center">
                          <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                            <template v-slot:button-content>
                              <img src="@/assets/images/plans_display/menu@2x.png" width="28px" height="9" />
                              <!-- <i class="mdi mdi-chevron-down" /> -->
                            </template>
                            <b-dropdown-item @click="askDelPlan(plan_data_selcet.rawData)">ลบแผนงาน</b-dropdown-item>
                          </b-dropdown>
                        </b-col>
                      </b-row>
                      <simplebar class="card-body" style="height:calc(100vh - 250px); padding: 0px 20px 0px 0px;font-size:15px">
                        <b-row class="col-margin-bot-1">
                          <b-col lg="3">
                            <span class="">
                              <b>{{ lang.plans_detail_display.code }}</b>
                            </span>
                          </b-col>
                          <b-col lg="9">
                            <span class="font-color-blue">
                              {{ plan_data_selcet.rawData.id_order_tracking }}
                            </span>
                          </b-col>
                        </b-row>
                        <b-row class="col-margin-bot-1">
                          <b-col lg="3">
                            <span class="">
                              <b>{{ lang.plans_detail_display.date_create }}</b>
                            </span>
                          </b-col>
                          <b-col lg="9">
                            <span class="font-color-blue">
                              {{ plan_data_selcet.rawData.created | moment('dd DD/MM/YYYY HH:mm') }}
                            </span>
                          </b-col>
                        </b-row>
                        <b-row class="col-margin-bot-1">
                          <b-col lg="3">
                            <span class=""> <b>{{ lang.plans_detail_display.status }} ยกเลิกแผนงาน</b> </span>
                          </b-col>
                          <b-col lg="9">
                            <span class=" font-color-blue">
                              {{ plan_data_selcet.status_name }}
                            </span>
                            <span v-show="plan_data_selcet.rawData.order_status < 2" class=" font-color-red"> ( เสนอราคาถึงวันที่ {{ plan_data_selcet.rawData.period | moment('dd DD/MM/YYYY') }} ) </span>
                          </b-col>
                        </b-row>
                        <b-row class="col-margin-bot-1">
                          <b-col lg="3">
                            <span class="">
                              <b>{{ lang.plans_detail_display.goods_type }}</b>
                            </span>
                          </b-col>
                          <b-col lg="9">
                            <span class=" font-color-blue">
                              {{ plan_data_selcet.goods_type_name_full }}
                            </span>
                          </b-col>
                        </b-row>
                        <b-row class="col-margin-bot-1">
                          <b-col lg="3">
                            <span class="">
                              <b>{{ lang.plans_detail_display.weight }}</b>
                            </span>
                          </b-col>
                          <b-col lg="9">
                            <span class=" font-color-blue"> {{ plan_data_selcet.rawData.quantity }} {{ lang.tons }} </span>
                          </b-col>
                        </b-row>
                        <b-row class="col-margin-bot-1">
                          <b-col lg="3">
                            <span class="">
                              <b>{{ lang.plans_detail_display.detail }}</b>
                            </span>
                          </b-col>
                          <b-col lg="9">
                            <span v-if="plan_data_selcet.rawData.question != ''" class=" font-color-blue">
                              {{ plan_data_selcet.rawData.question }}
                            </span>
                            <span v-else class=" font-color-blue">
                              -
                            </span>
                          </b-col>
                        </b-row>
                        <b-row class="col-margin-bot-1">
                          <b-col lg="3">
                            <span class="">
                              <b>{{ lang.plans_detail_display.location }}</b>
                            </span>
                          </b-col>
                          <b-col lg="9">
                            <ul class="list-unstyled activity-wid">
                              <li class="activity-list">
                                <b-row>
                                  <div class="activity-icon avatar-xs">
                                    <span class="avatar-title bg-soft-primary text-primary rounded-circle">
                                      <i :class="`ri-time-fill`" />
                                    </span>
                                  </div>
                                  <b-col>
                                    <span>
                                      {{ lang.plans_detail_display.pick_up_1 }}
                                    </span>
                                    <br />
                                    <span class="font-color-blue">
                                      {{ plan_data_selcet.rawData.departure_address }}
                                    </span>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col>
                                    <span class="font-color-blue">
                                      {{ plan_data_selcet.pickup_time }}
                                    </span>
                                  </b-col>
                                </b-row>
                                <br />
                              </li>
                              <li class="activity-list">
                                <b-row>
                                  <div class="activity-icon avatar-xs">
                                    <span class="avatar-title bg-soft-primary rounded-circle" style="color: #B0BAC4">
                                      <i :class="`ri-time-fill`" />
                                    </span>
                                  </div>
                                  <b-col>
                                    <span>
                                      {{ lang.plans_detail_display.drop_1 }}
                                    </span>
                                    <br />
                                    <span class="font-color-blue">
                                      {{ plan_data_selcet.rawData.arrival_address }}
                                    </span>
                                  </b-col>
                                </b-row>
                              </li>
                            </ul>
                          </b-col>
                        </b-row>
                        <b-row class="col-margin-bot-1">
                          <b-col lg="3">
                            <span class="">
                              <b>{{ lang.plans_detail_display.map }}</b>
                            </span>
                          </b-col>
                          <b-col lg="9">
                            <ModalMap :obj="plan_data_selcet.rawData" />
                          </b-col>
                        </b-row>
                        <b-row class="col-margin-bot-1">
                          <b-col lg="3">
                            <span class="">
                              <b>{{ lang.plans_detail_display.total_distance }}</b>
                            </span>
                          </b-col>
                          <b-col lg="9">
                            <span class=" font-color-blue"> {{ plan_data_selcet.rawData.total_distance }} {{ lang.plans_detail_display.km }} </span>
                          </b-col>
                        </b-row>
                        <b-row class="col-margin-bot-1">
                          <b-col lg="3">
                            <span class="">
                              <b>{{ lang.plans_detail_display.truck_type }}</b>
                            </span>
                          </b-col>
                          <b-col lg="9">
                            <span class="">
                              <img v-if="plan_data_selcet.truck_type_group == '1'" src="@/assets/images/truck_img/4_wheels.png" width="40px" style="margin-right: 20px" />
                              <img v-else-if="plan_data_selcet.truck_type_group == '2'" src="@/assets/images/truck_img/6_wheels.png" width="40px" style="margin-right: 20px" />
                              <img v-else-if="plan_data_selcet.truck_type_group == '3'" src="@/assets/images/truck_img/10_wheels.png" width="40px" style="margin-right: 20px" />
                              <img v-else-if="plan_data_selcet.truck_type_group == '4'" src="@/assets/images/truck_img/trailer2.png" width="40px" style="margin-right: 20px" />
                              <img v-else-if="plan_data_selcet.truck_type_group == '5'" src="@/assets/images/truck_img/trailer.png" width="40px" style="margin-right: 20px" />
                              <img v-else-if="plan_data_selcet.truck_type_group == '6'" src="@/assets/images/truck_img/tow_truck.png" width="40px" style="margin-right: 20px" />
                              {{ plan_data_selcet.truck_type_name }}
                            </span>
                          </b-col>
                        </b-row>
                        <b-row class="col-margin-bot-1">
                          <b-col lg="3">
                            <span class="">
                              <b>{{ lang.plans_detail_display.services }}</b>
                            </span>
                          </b-col>
                          <b-col lg="9">
                            <span v-if="plan_data_selcet.rawData.other_services != ''" class=" font-color-blue">
                              {{ findService_other(plan_data_selcet.rawData) }}
                            </span>
                            <span v-else class=" font-color-blue">
                              -
                            </span>
                          </b-col>
                        </b-row>
                        <b-row class="col-margin-bot-1">
                          <b-col lg="3">
                            <span class="">
                              <b>{{ lang.plans_detail_display.amount }}</b>
                            </span>
                          </b-col>
                          <b-col lg="9">
                            <span v-if="plan_data_selcet.conv_price != ''" class=""> {{ plan_data_selcet.conv_price }} {{ lang.bath }} </span>
                            <span v-else class="">
                              -
                            </span>
                          </b-col>
                        </b-row>
                      </simplebar>
                    </div>
                  </div>
                </b-col>
                <!-- ส่วนหน้าต่าง ผู้ให้บริการ -->
                <template>
                  <b-col v-if="!baggage_display_status">
                    <div class="card-body" style="max-height: 87.5vh; min-height: 87.5vh; padding 1.25rem; margin-right: 0.6rem;">
                      <div style="padding: 1% 1% 0 1%;">
                        <nav>
                          <b-tabs v-model="active_tab" justified nav-class="nav-tabs-custom">
                            <b-tab :active="active_tab == 0">
                              <template v-slot:title>
                                <span class="d-none d-sm-inline-block topnav-text">{{ lang.provider_display.tab1.title }}</span>
                                <span style="color:red;"> ({{ service_provider_data.length }})</span>
                              </template>
                            </b-tab>
                            <b-tab :active="active_tab == 1">
                              <template v-slot:title>
                                <span class="d-none d-sm-inline-block topnav-text">{{ lang.provider_display.tab2.title }}</span>
                                <span style="color:red;"> ({{ offer_provider_data.length }})</span>
                              </template>
                            </b-tab>
                          </b-tabs>
                        </nav>
                        <!-- ส่วนหน้าต่าง ผู้ให้บริการ -->
                        <template v-if="active_tab == 0">
                          <div style="padding: 20px 20px 0px 20px; background: #F7F7F7 0% 0% no-repeat padding-box;">
                            <b-row>
                              <b-col lg="4">
                                <select v-model="sort_by_provider_status" class="form-control" @change="getProviderData('provider')">
                                  <option value="0" selected>สถานะทั้งหมด</option>
                                  <option value="1">ไม่มีรถพร้อมให้บริการ</option>
                                  <option value="2">มีรถพร้อมให้บริการ</option>
                                  <option value="3">รอพิจารณา</option>
                                </select>
                              </b-col>
                              <b-col lg="8" class="col-margin-bot-1">
                                <div class="input-group prepend">
                                  <b-form-input id="search_plans_text" v-model="search_provider_text" type="text" class="form-control custom-input-prepend" :placeholder="lang.placeholder.search_provider" @keyup="timeout_search_provider()" />
                                  <div class="input-group-prepend custom-input-group-prepend">
                                    <span class="input-group-text custom-input-group-prepend-text search"><i class="ri-search-line" @click="getProviderData('provider')" /></span>
                                  </div>
                                </div>
                              </b-col>
                            </b-row>
                          </div>
                          <!-- ส่วนหน้าต่าง ผู้ให้บริการ -->
                          <simplebar class="service-provider-display" style="max-height: 66.5vh; min-height: 66.5vh; background: #F7F7F7 0% 0% no-repeat padding-box;">
                            <div role="tablist">
                              <div v-if="search_flag" class="d-flex align-items-center justify-content-center">
                                <span>
                                  <br />
                                  <br />
                                  <b-spinner class="m-2" variant="primary" role="status" />
                                </span>
                              </div>
                              <div v-else>
                                <b-card v-for="(option, index) in service_provider_data" :key="index" no-body class="mb-1 shadow-none service-provider-goods-row" :class="{ active: service_provider_data[index].active_status }">
                                  <div>
                                    <b-row class="col-margin-bot-1">
                                      <b-col lg="2">
                                        <div class="d-flex align-items-center">
                                          <div style="margin:0 10px;margin-top:5px;">
                                            <i :class="option.star_flag == '1' ? 'ri-star-fill': 'ri-star-line'" :style="option.star_flag == '1' ? 'color:#FAAB00;cursor:pointer;font-size:25px;' : 'cursor:pointer;font-size:25px;'" @click="updateStar(option, index)" />
                                          </div>
                                          <img v-if="option.provider_profile_pic" :src="option.provider_profile_pic" height="40px" class="rounded-circle avatar-sm" />
                                          <img v-else src="@/assets/images/plans_display/Group 3361@2x.png" height="40px" class="rounded-circle avatar-sm" />
                                        </div>
                                      </b-col>
                                      <b-col lg="10" style="cursor:pointer" @click="cilck_provider(index, 'service')">
                                        <b-row>
                                          <b-col>
                                            <span>
                                              <b>{{ option.provider_name }}</b>
                                            </span>
                                          </b-col>
                                        </b-row>
                                        <b-row>
                                          <b-col class="d-flex align-items-center">
                                            <b-row>
                                              <b-col>
                                                <span class="font-size-14" style="margin-right: 0.5rem">
                                                  ผู้สนใจ/Rating :
                                                </span>
                                                <img src="@/assets/images/plans_display/star@2x.png" width="16px" height="16px" />
                                                <span class="font-size-14" style="margin-right: 0.5rem"> ( {{ option.star_score }} ) </span>
                                                <img src="@/assets/images/plans_display/lover.png" width="16px" height="16px" />
                                                <span class="font-size-14"> ( {{ option.lover_score }} ) </span>
                                              </b-col>
                                            </b-row>
                                          </b-col>
                                          <i class="fas fa-angle-down arrow" :class="{ down: service_provider_data[index].active_status }" style="position: absolute; top:20px; right:0; cursor: pointer;" @click="cilck_provider(index, 'service')" />
                                        </b-row>
                                      </b-col>
                                    </b-row>
                                    <b-row class="col-margin-bot-1">
                                      <b-col lg="6">
                                        <span> สถานะ : </span>
                                        <span v-if="option.provider_status_code == '1'" style="color: #FF5D5D"> ไม่มีรถพร้อมให้บริการ </span>
                                        <span v-else-if="option.provider_status_code == '2'" style="color: #33C38F"> มีรถพร้อมให้บริการ </span>
                                        <span v-else-if="option.provider_status_code == '3'" style="color: #0098D1"> รอพิจารณา </span>
                                        <span v-else-if="option.provider_status_code == '4'" style="color: #0098D1"> ยื่นข้อเสนอใหม่ </span>
                                      </b-col>
                                      <b-col lg="6" class="font-size-15">
                                        <span> เบอร์ติดต่อ : {{ option.provider_tel }} </span>
                                      </b-col>
                                    </b-row>
                                  </div>
                                  <div class="service-provider-goods-row-body" :class="{ active: service_provider_data[index].active_status }">
                                    <b-row class="col-margin-bot-1">
                                      <b-col lg="5" style="">
                                        <span class="font-size-15"> อีเมล : <span class="font-size-12">{{ option.provider_email }}</span> </span>
                                      </b-col>
                                    </b-row>
                                    <b-row class="col-margin-bot-1">
                                      <b-col lg="6">
                                        <span class="font-size-15">ข้อมูลบริษัท : </span>
                                        <b-link class="font-size-15" href="#" @click="bt_detail(option)">{{ lang.provider_display.tab1.detail }}</b-link>
                                      </b-col>
                                      <b-col lg="5" style="">
                                      <!-- <span class="font-size-16">
                                      {{ option.provider_id }}
                                    </span> -->
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col>
                                        <div v-if="option.provider_plan_action_code == '1'" class="d-flex justify-content-center big_btn_red" @click="askDelProviderOffer(option, plan_data_selcet)">
                                          <span class="margin-top-bot-auto">ยกเลิกการเสนองาน</span>
                                        </div>
                                        <div v-else class="d-flex justify-content-center big_btn_blue" @click="askSendAlert(option, plan_data_selcet)">
                                          <span class="margin-top-bot-auto">เสนองาน</span>
                                        </div>
                                      </b-col>
                                    <!-- <b-col>
                                      <div class="d-flex justify-content-center big_btn_white_dis">
                                        <span class="margin-top-bot-auto">แชทกับผู้ให้บริการ (soon)</span>
                                      </div>
                                    </b-col> -->
                                    </b-row>
                                  </div>
                                </b-card>
                              </div>
                            </div>
                          </simplebar>
                        </template>
                        <!-- ส่วนหน้าต่าง ผู้ยื่นข้อเสนอ -->
                        <template v-else>
                          <div style="padding: 20px 20px 0px 20px; background: #F7F7F7 0% 0% no-repeat padding-box;">
                            <b-row>
                              <b-col lg="4">
                                <select v-model="sort_by_tenderer_status" class="form-control" @change="getProviderData('tenderer')">
                                  <option value="0" selected>สถานะทั้งหมด</option>
                                  <option value="3">รอพิจารณา</option>
                                  <option value="4">ยื่นข้อเสนอใหม่</option>
                                </select>
                              </b-col>
                              <b-col lg="8" class="col-margin-bot-1">
                                <div class="input-group prepend">
                                  <b-form-input id="search_plans_text" v-model="sort_by_tenderer_name" type="text" class="form-control custom-input-prepend" :placeholder="lang.placeholder.search_tenderer" @keyup="timeout_search_tenderer()" />
                                  <div class="input-group-prepend custom-input-group-prepend" @click="getProviderData('tenderer')">
                                    <span class="input-group-text custom-input-group-prepend-text search"><i class="ri-search-line" /></span>
                                  </div>
                                </div>
                              </b-col>
                            </b-row>
                          </div>
                          <simplebar class="service-provider-display" style="height:calc(100vh - 250px); background: #F7F7F7 0% 0% no-repeat padding-box;">
                            <div role="tablist">
                              <div v-if="search_flag" class="d-flex align-items-center justify-content-center">
                                <span>
                                  <br />
                                  <br />
                                  <b-spinner class="m-2" variant="primary" role="status" />
                                </span>
                              </div>
                              <div v-else>
                                <b-card v-for="(option, index) in offer_provider_data" :key="index" no-body class="mb-1 shadow-none offer-provider-goods-row" :class="{ 'active': offer_provider_data[index].active_status }">
                                  <div>
                                    <b-row class="col-margin-bot-1">
                                      <b-col lg="2">
                                        <div class="d-flex align-items-center" style="width:100%;">
                                          <div style="margin:0 10px;margin-top:5px;">
                                            <i :class="option.star_flag == '1' ? 'ri-star-fill': 'ri-star-line'" :style="option.star_flag == '1' ? 'color:#FAAB00;cursor:pointer;font-size:25px;' : 'cursor:pointer;font-size:25px;'" @click="updateStar(option, index)" />
                                          </div>
                                          <div>
                                            <img v-if="option.provider_profile_pic" :src="option.provider_profile_pic" height="40px" class="rounded-circle avatar-sm" />
                                            <img v-else src="@/assets/images/plans_display/Group 3361@2x.png" height="40px" class="rounded-circle avatar-sm" />
                                          </div>
                                        </div>
                                      </b-col>
                                      <b-col lg="5" style="cursor:pointer" @click="cilck_provider(index, 'offer')">
                                        <b-row>
                                          <b-col>
                                            <span class="font-size-16">
                                              <b>{{ option.provider_name }}</b>
                                            </span>
                                          </b-col>
                                        </b-row>
                                        <b-row class="col-margin-bot-1">
                                          <b-col>
                                            <span class="font-size-14" style="margin-right: 0.5rem">
                                              ผู้สนใจ/Rating :
                                            </span>
                                            <img src="@/assets/images/plans_display/star@2x.png" width="16px" height="16px" />
                                            <span class="font-size-14" style="margin-right: 0.5rem"> ( {{ option.star_score }} ) </span>
                                            <img src="@/assets/images/plans_display/lover.png" width="16px" height="16px" />
                                            <span class="font-size-14"> ( {{ option.lover_score }} ) </span>
                                          </b-col>
                                        </b-row>
                                      </b-col>
                                      <b-col lg="5" style="cursor:pointer" @click="cilck_provider(index, 'offer')">
                                        <b-row>
                                      &nbsp;
                                        </b-row>
                                        <b-row>
                                          <span>
                                            <img src="@/assets/images/plans_display/calendar@2x.png" style="height:17px;" />
                                            {{ option.estimate_data.created | moment('dd DD/MM/YYYY HH:mm') }}
                                          </span>
                                        </b-row>
                                      </b-col>
                                      <i class="fas fa-angle-down arrow" :class="{ down: offer_provider_data[index].active_status }" style="position: absolute; top:5px; right:0; cursor:pointer;" @click="cilck_provider(index, 'service')" />
                                    </b-row>
                                    <b-row class="col-margin-bot-1">
                                      <b-col lg="6" class="">
                                        <!-- <span> สถานะ : {{ option.provider_status_name }} </span> -->
                                        <span> สถานะ : </span>
                                        <span v-if="option.provider_status_code == '1'" style="color: #FF5D5D"> ไม่มีรถพร้อมให้บริการ </span>
                                        <span v-else-if="option.provider_status_code == '2'" style="color: #33C38F"> มีรถพร้อมให้บริการ </span>
                                        <span v-else-if="option.provider_status_code == '3'" style="color: #0098D1"> รอพิจารณา </span>
                                        <span v-else-if="option.provider_status_code == '4'" style="color: #0098D1"> ยื่นข้อเสนอใหม่ </span>
                                      </b-col>
                                      <b-col lg="5" style="margin-left: 2.3rem">
                                        <span class="font-size-16 offer-provider-goods-row-tel" :class="{ active: offer_provider_data[index].active_status }"> เบอร์ติดต่อ : {{ option.provider_tel }} </span>
                                      </b-col>
                                    </b-row>
                                  </div>
                                  <div class="offer-provider-goods-row-body" :class="{ active: offer_provider_data[index].active_status, 'reject': offer_provider_data[index].estimate_data.reject_flg == '1' }">
                                    <!-- <b-row class="col-margin-bot-1">
                                    <b-col lg="6" class="font-size-16">
                                      <span> สถานะ : {{ option.provider_status_name }} </span>
                                      <span> สถานะ : </span>
                                      <span v-if="option.provider_status_code == '3'" style="color: #0098D1"> รอพิจารณา </span>
                                      <span v-else-if="option.provider_status_code == '4'" style="color: #0098D1"> ยื่นข้อเสนอใหม่ </span>
                                    </b-col>
                                    <b-col lg="5" style="margin-left: 2.3rem">
                                      <span class="font-size-16"> เบอร์ติดต่อ : {{ option.provider_tel }} </span>
                                    </b-col>
                                  </b-row> -->
                                    <b-row class="col-margin-bot-2">
                                      <b-col lg="6">
                                        <span class="font-size-16"> อีเมล : {{ option.provider_email }} </span>
                                      </b-col>
                                      <b-col lg="5" style="margin-left: 2.3rem">
                                        <span class="font-size-16">ข้อมูลบริษัท : </span>
                                        <b-link class="font-size-16" href="#" @click="bt_detail(option)">{{ lang.provider_display.tab2.detail }}</b-link>
                                      </b-col>
                                    </b-row>
                                    <b-row class="col-margin-bot-1">
                                      <b-col lg="12">
                                        <div style="padding: 20px; background: #F7F7F7;">
                                          <b-row class="col-margin-bot-1">
                                            <b-col lg="12">
                                              <span class="font-size-20 font-weight-bold"> เสนอครั้งที่ {{ option.estimate_data.num_estimate }} </span>
                                              <span> ({{ option.estimate_data.lastmod | moment('dd DD/MM/YYYY HH:mm') }}) </span>
                                            </b-col>
                                          </b-row>
                                          <b-row class="col-margin-bot-1">
                                            <b-col lg="6" class="font-size-16 font-weight-bold">
                                              <img v-if="option.estimate_data.vehicle_type_group_id == '1'" src="@/assets/images/truck_img/4_wheels.png" width="60px" style="margin-right: 20px" />
                                              <img v-else-if="option.estimate_data.vehicle_type_group_id == '2'" src="@/assets/images/truck_img/6_wheels.png" width="60px" style="margin-right: 20px" />
                                              <img v-else-if="option.estimate_data.vehicle_type_group_id == '3'" src="@/assets/images/truck_img/10_wheels.png" width="60px" style="margin-right: 20px" />
                                              <img v-else-if="option.estimate_data.vehicle_type_group_id == '4'" src="@/assets/images/truck_img/trailer2.png" width="60px" style="margin-right: 20px" />
                                              <img v-else-if="option.estimate_data.vehicle_type_group_id == '5'" src="@/assets/images/truck_img/trailer.png" width="60px" style="margin-right: 20px" />
                                              <img v-else-if="option.estimate_data.vehicle_type_group_id == '6'" src="@/assets/images/truck_img/tow_truck.png" width="60px" style="margin-right: 20px" />
                                              {{ findTruckTypeName(option.estimate_data.vehicle_type_id) }}
                                            </b-col>
                                            <b-col lg="5" class="font-size-16">
                                              <span>
                                                เสนอราคา :
                                              </span>
                                              <span class="font-size-20 font-weight-bold"> {{ option.estimate_data.price }} {{ lang.bath }} </span>
                                            <!-- <span v-if="option.minus_price_flg == '0'" class="font-color-red">
                                              ({{ option.diff_price }})
                                            </span>
                                            <span v-else class="font-color-green">
                                              ({{ option.diff_price }})
                                            </span> -->
                                            </b-col>
                                          </b-row>
                                          <b-row class="col-margin-bot-1">
                                            <b-col lg="6" class="font-size-16">
                                              <span>
                                                จำนวนรถ :
                                              </span>
                                              <span class="font-color-blue"> {{ option.estimate_data.number_of_cars }} {{ lang.car }} </span>
                                            </b-col>
                                            <b-col lg="5" class="font-size-16">
                                              ใบเสนอราคา :
                                              <a target="_blank" class="font-color-blue font_hover" style="color:black;" @click="openFileTab(option.estimate_data)">
                                                <u>{{ option.estimate_data.filename }} </u>
                                              </a>
                                            </b-col>
                                          </b-row>
                                          <b-row class="col-margin-bot-1">
                                            <b-col lg="12" class="font-size-16">
                                              <span>
                                                หมายเหตุ :
                                              </span>
                                              <span class="font-color-blue">
                                                {{ option.estimate_data.propose }}
                                              </span>
                                            </b-col>
                                          </b-row>
                                        </div>
                                      </b-col>
                                    </b-row>
                                    <b-row v-if="option.estimate_data.reject_flg != '1'" class="col-margin-bot-2">
                                      <b-col lg="12">
                                        <span class="font-size-16 font-weight-bold font-color-blue font_hover" @click="openAllEsitmate(option)">ข้อเสนอทั้งหมด</span>
                                      </b-col>
                                    </b-row>
                                    <template v-if="option.estimate_data.reject_flg == '1'">
                                      <b-row>
                                        <b-col>
                                          <div style="background: #FFF6E0 0% 0% no-repeat padding-box; margin-bottom:10px;">
                                            <b-row class="col-margin-bot-1" style="padding-left: 12px; padding-top: 12px; padding-bottom: 12px;">
                                              <b-col lg="12"> <b>สาเหตุการยื่นข้อเสนอใหม่</b> : {{ option.estimate_data.reject_comment }} </b-col>
                                            </b-row>
                                          </div>
                                        </b-col>
                                      </b-row>
                                      <b-row class="col-margin-bot-2">
                                        <b-col lg="12">
                                          <span class="font-size-16 font-weight-bold font-color-blue font_hover" @click="openAllEsitmate(option)">ข้อเสนอทั้งหมด</span>
                                        </b-col>
                                      </b-row>
                                      <b-row>
                                      <!-- <b-col>
                                        <div class="d-flex justify-content-center big_btn_white_dis">
                                          <span class="margin-top-bot-auto">แชทกับผู้ให้บริการ (soon)</span>
                                        </div>
                                      </b-col> -->
                                      </b-row>
                                    </template>
                                    <template v-else-if="option.estimate_data.accept_flg == '0'">
                                      <b-row style="margin-bottom: 1.1rem">
                                        <b-col>
                                          <div class="d-flex justify-content-center big_btn_green" @click="askAcceptOffer(option)">
                                            <span class="margin-top-bot-auto">ยอมรับ/เลือกผู้ให้บริการนี้</span>
                                          </div>
                                        </b-col>
                                        <b-col>
                                          <div class="d-flex justify-content-center big_btn_orange" @click="askRejectOffer(option)">
                                            <span class="margin-top-bot-auto">ยื่นข้อเสนอใหม่</span>
                                          </div>
                                        </b-col>
                                      </b-row>
                                      <b-row>
                                        <b-col>
                                          <div class="d-flex justify-content-center big_btn_red_white_bg" @click="askDeleteOffer(option)">
                                            <span class="margin-top-bot-auto">ฉันไม่สนใจผู้ให้บริการนี้</span>
                                          </div>
                                        </b-col>
                                      </b-row>
                                    </template>
                                  <!-- <template v-else>
                                  <b-row style="margin-bottom: 1.1rem">
                                    <b-col>
                                      <div class="d-flex justify-content-center big_btn_blue">
                                        <span class="margin-top-bot-auto">ติดตามสินค้า</span>
                                      </div>
                                    </b-col>
                                    <b-col>
                                      <div class="d-flex justify-content-center big_btn_white">
                                        <span class="margin-top-bot-auto">แชทกับผู้ให้บริการ</span>
                                      </div> 
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col>
                                      <div class="d-flex justify-content-center big_btn_green" @click="askFinPlan(option)">
                                        <span class="margin-top-bot-auto">จบแผนงาน</span>
                                      </div>
                                    </b-col>
                                    <b-col>
                                      <div class="d-flex justify-content-center big_btn_red" @click="askCancelPlan(option)">
                                        <span class="margin-top-bot-auto">ยกเลิกงาน</span>
                                      </div> 
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col>
                                      <span class="font-color-red font-size-12">
                                        กรณียกเลิกงานก่อนวันเริ่มงาน อาจมีการเรียกร้องค่าเสียหายจากผู้ให้บริการ
                                      </span>
                                    </b-col>
                                  </b-row>
                                </template> -->
                                  </div>
                                </b-card>
                              </div>
                            </div>
                          </simplebar>
                        </template>
                      </div>
                    </div>
                  </b-col>
                  <b-col v-else>
                    <div class="card-body" style="height:calc(100vh - 250px); padding 1.25rem; margin-right: 0.6rem;">
                      <div style="padding: 1% 1% 0 1%;">
                        <template>
                          <br />
                          <b-row style="padding: 0px 20px 0px 20px">
                            <b-col>
                              <span style="font-size: 18px; font-weight: 500;">
                                <b>ผู้ให้บริการขนส่ง</b>
                              </span>
                            </b-col>
                          </b-row>
                          <simplebar class="service-provider-display" style="max-height: 66.5vh; min-height: 66.5vh;">
                            <div role="tablist">
                              <div v-for="(option, index) in offer_provider_data" :key="index">
                                <b-card v-if="option.estimate_data.id_member == baggage_raw_data[0].id_member_carrier && option.estimate_data.id_order == baggage_raw_data[0].id_order" no-body class="mb-1 shadow-none offer-provider-goods-row active">
                                  <b-row class="col-margin-bot-1">
                                    <div class="d-flex align-items-center">
                                      <div style="margin:0 10px;margin-top:5px;">
                                        <i :class="option.star_flag == '1' ? 'ri-star-fill': 'ri-star-line'" :style="option.star_flag == '1' ? 'color:#FAAB00;cursor:pointer;font-size:25px;' : 'cursor:pointer;font-size:25px;'" @click="updateStar(option, index)" />
                                      </div>
                                      <img v-if="option.provider_profile_pic" :src="option.provider_profile_pic" width="40px" height="40px" class="rounded-circle avatar-sm" />
                                      <img v-else src="@/assets/images/plans_display/Group 3361@2x.png" width="40px" height="40px" class="rounded-circle avatar-sm" />
                                    </div>
                                    <b-col lg="10" style="margin-left: 1rem;">
                                      <b-row>
                                        <b-col>
                                          <span class="font-size-16">
                                            {{ option.provider_name }}
                                          </span>
                                        </b-col>
                                      </b-row>
                                      <b-row>
                                        <b-col class="d-flex align-items-center">
                                          <b-row>
                                            <b-col>
                                              <span class="font-size-14" style="margin-right: 0.5rem">
                                                ผู้สนใจ/Rating :
                                              </span>
                                              <img src="@/assets/images/plans_display/star@2x.png" width="16px" height="16px" />
                                              <span class="font-size-14" style="margin-right: 0.5rem"> ({{ option.star_score }}) </span>
                                              <img src="@/assets/images/plans_display/lover.png" width="16px" height="16px" />
                                              <span class="font-size-14"> ({{ option.lover_score }}) </span>
                                            </b-col>
                                          </b-row>
                                        </b-col>
                                        <b-col>
                                          <img src="@/assets/images/plans_display/calendar@2x.png" width="16px" height="16px" style="margin-right: 5px" />
                                          <span>
                                            {{ option.estimate_data.created | moment('dd DD/MM/YYYY HH:mm') }}
                                          </span>
                                        </b-col>
                                      </b-row>
                                    </b-col>
                                    <!-- <i class="fas fa-angle-down arrow" :class="{ down: offer_provider_data[index].active_status }" style="position: absolute; top:20px; right:0;" /> -->
                                  </b-row>
                                  <div class="offer-provider-goods-row-body-fin active">
                                    <b-row class="col-margin-bot-1">
                                      <!-- <b-col lg="6" class="font-size-16">
                                        <span> สถานะ : {{ option.provider_status_name }} </span>
                                      </b-col> -->
                                      <b-col lg="6">
                                        <span class="font-size-16"> เบอร์ติดต่อ : {{ option.provider_tel }} </span>
                                      </b-col>
                                      <b-col lg="5" style="margin-left: 2.3rem">
                                        <span class="font-size-16"> อีเมล : {{ option.provider_email }} </span>
                                      </b-col>
                                    </b-row>
                                    <b-row class="col-margin-bot-2">
                                      <b-col lg="6">
                                        <span class="font-size-16">ข้อมูลบริษัท : </span>
                                        <b-link class="font-size-16" href="#" @click="bt_detail(option)">{{ lang.provider_display.tab2.detail }}</b-link>
                                      </b-col>
                                    </b-row>
                                    <b-row class="col-margin-bot-1">
                                      <b-col lg="12">
                                        <div style="padding: 20px; background: #F7F7F7;">
                                          <b-row class="col-margin-bot-1">
                                            <b-col lg="12">
                                              <span class="font-size-20 font-weight-bold"> เสนอครั้งที่ {{ option.estimate_data.num_estimate }} </span>
                                              <span> ({{ option.estimate_data.lastmod | moment('dd DD/MM/YYYY HH:mm') }}) </span>
                                            </b-col>
                                          </b-row>
                                          <b-row class="col-margin-bot-1">
                                            <b-col lg="6" class="font-size-16 font-weight-bold">
                                              <img v-if="option.estimate_data.vehicle_type_group_id == '1'" src="@/assets/images/truck_img/4_wheels.png" width="60px" style="margin-right: 20px" />
                                              <img v-else-if="option.estimate_data.vehicle_type_group_id == '2'" src="@/assets/images/truck_img/6_wheels.png" width="60px" style="margin-right: 20px" />
                                              <img v-else-if="option.estimate_data.vehicle_type_group_id == '3'" src="@/assets/images/truck_img/10_wheels.png" width="60px" style="margin-right: 20px" />
                                              <img v-else-if="option.estimate_data.vehicle_type_group_id == '4'" src="@/assets/images/truck_img/trailer2.png" width="60px" style="margin-right: 20px" />
                                              <img v-else-if="option.estimate_data.vehicle_type_group_id == '5'" src="@/assets/images/truck_img/trailer.png" width="60px" style="margin-right: 20px" />
                                              <img v-else-if="option.estimate_data.vehicle_type_group_id == '6'" src="@/assets/images/truck_img/tow_truck.png" width="60px" style="margin-right: 20px" />
                                              {{ findTruckTypeName(option.estimate_data.vehicle_type_id) }}
                                            </b-col>
                                            <b-col lg="5" class="font-size-16">
                                              <span>
                                                เสนอราคา :
                                              </span>
                                              <span class="font-size-20 font-weight-bold"> {{ option.estimate_data.price }} {{ lang.bath }} </span>
                                              <!-- <span v-if="option.minus_price_flg == '0'" class="font-color-red">
                                              ({{ option.diff_price }})
                                            </span>
                                            <span v-else class="font-color-green">
                                              ({{ option.diff_price }})
                                            </span> -->
                                            </b-col>
                                          </b-row>
                                          <b-row class="col-margin-bot-1">
                                            <b-col lg="6" class="font-size-16">
                                              <span>
                                                จำนวนรถ :
                                              </span>
                                              <span class="font-color-blue"> {{ option.estimate_data.number_of_cars }} {{ lang.car }} </span>
                                            </b-col>
                                            <b-col lg="5" class="font-size-16">
                                              ใบเสนอราคา :
                                              <a target="_blank" class="font-color-blue font_hover" style="color:black;" @click="openFileTab(option.estimate_data)">
                                                <u>{{ option.estimate_data.filename }} </u>
                                              </a>
                                            </b-col>
                                          </b-row>
                                          <b-row class="col-margin-bot-1">
                                            <b-col lg="12" class="font-size-16">
                                              <span>
                                                หมายเหตุ :
                                              </span>
                                              <span class="font-color-blue">
                                                {{ option.estimate_data.propose }}
                                              </span>
                                            </b-col>
                                          </b-row>
                                        </div>
                                      </b-col>
                                    </b-row>
                                    <b-row class="col-margin-bot-2">
                                      <b-col lg="12">
                                        <span class="font-size-16 font-weight-bold font-color-blue font_hover" @click="openAllEsitmate(option)">ข้อเสนอทั้งหมด</span>
                                      </b-col>
                                    </b-row>
                                    <template v-if="option.estimate_data.accept_flg == '0'">
                                      <b-row style="margin-bottom: 1.1rem">
                                        <b-col>
                                          <div class="d-flex justify-content-center big_btn_green" @click="askAcceptOffer(option)">
                                            <span class="margin-top-bot-auto">ยอมรับ/เลือกผู้ให้บริการนี้</span>
                                          </div>
                                        </b-col>
                                        <b-col>
                                          <div class="d-flex justify-content-center big_btn_orange" @click="askRejectOffer(option)">
                                            <span class="margin-top-bot-auto">ยื่นข้อเสนอใหม่</span>
                                          </div>
                                        </b-col>
                                      </b-row>
                                      <b-row>
                                        <b-col>
                                          <div class="d-flex justify-content-center big_btn_red_white_bg" @click="askDeleteOffer(option)">
                                            <span class="margin-top-bot-auto">ฉันไม่สนใจผู้ให้บริการนี้</span>
                                          </div>
                                        </b-col>
                                      </b-row>
                                    </template>
                                    <template v-else>
                                      <template v-if="option.estimate_data.estimate_status != '5'">
                                        <b-row style="margin-bottom: 1.1rem">
                                          <b-col>
                                            <div class="d-flex justify-content-center big_btn_blue" @click="routersClick('tracking',option)">
                                              <span class="margin-top-bot-auto">{{ lang.btn.tracking }}</span>
                                            </div>
                                          </b-col>
                                          <!-- <b-col>
                                            <div class="d-flex justify-content-center big_btn_white_dis">
                                              <span class="margin-top-bot-auto">แชทกับผู้ให้บริการ (soon)</span>
                                            </div>
                                          </b-col> -->
                                        </b-row>
                                        <b-row>
                                          <b-col v-if="option.estimate_data.estimate_status == '4'">
                                            <div class="d-flex justify-content-center big_btn_green" @click="openProofModal(option)">
                                              <span class="margin-top-bot-auto">{{ lang.btn.proof_fin_job }}</span>
                                            </div>
                                          </b-col>
                                          <b-col v-else>
                                            <div class="d-flex justify-content-center big_btn_green_dis">
                                              <span class="margin-top-bot-auto">{{ lang.btn.proof_fin_job }}</span>
                                            </div>
                                          </b-col>
                                          <b-col v-if="option.estimate_data.estimate_status == '0' || option.estimate_data.estimate_status == '1' || option.estimate_data.estimate_status == '2'">
                                            <div class="d-flex justify-content-center big_btn_red" @click="askCancelPlan(option)">
                                              <span class="margin-top-bot-auto">{{ lang.btn.cancel_job }}</span>
                                            </div>
                                          </b-col>
                                          <b-col v-else>
                                            <div class="d-flex justify-content-center big_btn_red_dis">
                                              <span class="margin-top-bot-auto">{{ lang.btn.cancel_job }}</span>
                                            </div>
                                          </b-col>
                                        </b-row>
                                        <br />
                                        <b-row>
                                          <b-col class="d-flex justify-content-center">
                                            <span class="font-color-red font-size-16 font-weight-bold">
                                              กรณียกเลิกงานก่อนวันเริ่มงาน อาจมีการเรียกร้องค่าเสียหายจากผู้ให้บริการ
                                            </span>
                                          </b-col>
                                        </b-row>
                                      </template>
                                      <template v-else>
                                        <hr />
                                        <b-row>
                                          <b-col class="d-flex justify-content-center">
                                            <span class="font-color-red font-size-20 font-weight-bold"> ปิดงานแล้ว ({{ option.estimate_data.fin_plan_time | moment('dd DD/MM/YYYY HH:mm') }}) </span>
                                          </b-col>
                                        </b-row>
                                        <hr />
                                      </template>
                                    </template>
                                  </div>
                                </b-card>
                              </div>
                            </div>
                          </simplebar>
                        </template>
                      </div>
                    </div>
                  </b-col>
                </template>
              </b-row>
            </div>
          </div>
        </transition>
        <ModalEstimate ref="modal_all_estimate" />
        <div style="padding: 2rem 2rem 0rem 2rem;">
          <b-row>
            <b-col lg="2" class="col-margin-bot-1">
              <select v-model="order_sel" class="form-control" @change="getPlansData()">
                <option value="0" selected hidden>เรียงตาม</option>
                <option value="1">วันที่สร้างแผนงาน</option>
                <option value="2">วันที่เริ่มแผนงาน</option>
              </select>
            </b-col>
            <b-col lg="2" class="col-margin-bot-1">
              <select v-model="sort_by_status" class="form-control" @change="getPlansData()">
                <option value="0" selected>สถานะทั้งหมด</option>
                <option value="1">รอเสนองาน</option>
                <option value="2">รอดำเนินการ</option>
                <option value="3">อยู่ระหว่างขนส่ง</option>
                <option value="4">ดำเนินการเสร็จแล้ว</option>
                <option value="5">ปิดงาน</option>
              </select>
            </b-col>
            <b-col lg="2" class="col-margin-bot-1">
              <date-picker v-model="search_date_start" input-class="form-control" format="DD-MM-YYYY" type="date" :placeholder="`${lang.placeholder.start_plan_date}`" />
            </b-col>
            <!-- <b-col lg="3">
              <b-link class="font-size-16" href="#" @click="activeSelectTruckType">เลือกประเภทรถ</b-link>
              <b-modal id="modal_select_truckType" centered size="lg" :title="lang.plans_detail_display.truck_type" title-class="font-size-24" hide-footer>
                <SelectTruckType :obj="[dataTruckType, dataTruckTypeGroup]" @sendTruckTypeData="getTruckType_select" />
              </b-modal>
            </b-col> -->
            <b-col lg="3" />
            <b-col lg="3" class="col-margin-bot-1">
              <div class="input-group prepend">
                <b-form-input id="search_plans_text" v-model="search_plans_text" type="text" class="form-control custom-input-prepend" :placeholder="lang.placeholder.search_plans_text" @keyup="timeout_search_plans()" />
                <div class="input-group-prepend custom-input-group-prepend">
                  <span class="input-group-text custom-input-group-prepend-text search"><i class="ri-search-line" @click="search_plan('plan_code')" /></span>
                </div>
              </div>
            </b-col>
          </b-row>
          <!-- ส่วนแสดงแผนงานขนส่ง -->
          <b-row v-if="plans_data_status">
            <simplebar id="bankS" ref="bankscrol" class="card-body" style="padding: 0px;height:calc(100vh - 200px);">
              <transition-group name="bounce" class="custom-transition" enter-active-class="bounceInLeft" leave-active-class="bounceOutRight" tag="div">
                <b-col v-for="(option, index) in plans_row_data" :key="index+1" lg="12" class="plans-col">
                  <div class="plans-row-data" @click="activePlansDisplay(option)">
                    <b-row class="custom-margin">
                      <b-col>
                        <span class="plans-row-data-title">
                          <img v-if="option.truck_type_group == '1'" src="@/assets/images/truck_img/4_wheels.png" height="25px" style="margin-right: 20px" />
                          <img v-else-if="option.truck_type_group == '2'" src="@/assets/images/truck_img/6_wheels.png" height="25px" style="margin-right: 20px" />
                          <img v-else-if="option.truck_type_group == '3'" src="@/assets/images/truck_img/10_wheels.png" height="25px" style="margin-right: 20px" />
                          <img v-else-if="option.truck_type_group == '4'" src="@/assets/images/truck_img/trailer2.png" height="25px" style="margin-right: 20px" />
                          <img v-else-if="option.truck_type_group == '5'" src="@/assets/images/truck_img/trailer.png" height="25px" style="margin-right: 20px" />
                          <img v-else-if="option.truck_type_group == '6'" src="@/assets/images/truck_img/tow_truck.png" height="25px" style="margin-right: 20px" />
                          <b style="font-size:18px;">{{ option.truck_type_name }}</b>
                        </span>
                      </b-col>
                      <b-col style="justify-items: baseline;">
                        <span class="plans-row-data-content"> {{ lang.code }} : </span>
                        <span class="font-color-blue">
                          {{ option.rawData.id_order_tracking }}
                        </span>
                      </b-col>
                      <b-col>
                        <span class="plans-row-data-content"> {{ lang.create_date }} : </span>
                        <span class="font-color-blue">
                          {{ option.rawData.created | moment('dd DD/MM/YYYY HH:mm') }}
                        </span>
                      </b-col>
                      <b-col>
                        <span class="plans-row-data-content"> {{ lang.prices }} : </span>
                        <span class="">
                          <!-- {{ option.rawData.expected_price }} {{ lang.bath }} -->
                          <b style="font-size:18px;">{{ option.conv_price }} {{ lang.bath }}</b>
                        </span>
                      </b-col>
                    </b-row>
                    <b-row class="custom-margin">
                      <b-col>
                        <span class="plans-row-data-content"> {{ lang.goods_type }} : </span>
                        <span class="font-color-blue">
                          {{ option.goods_type_name }}
                        </span>
                      </b-col>
                      <b-col>
                        <span class="plans-row-data-content"> {{ lang.weight }} : </span>
                        <span class="font-color-blue"> {{ option.rawData.quantity }} {{ lang.tons }} </span>
                      </b-col>
                      <b-col>
                        <span class="plans-row-data-content"> {{ lang.status }} : </span>
                        <span class="font-color-blue">
                          {{ option.status_name }}
                        </span>
                        <span v-show="option.status_id < 2" class="font-color-red"> ( เสนอราคาถึงวันที่ {{ option.rawData.period | moment('dd DD/MM/YYYY') }} ) </span>
                      </b-col>
                      <b-col />
                    </b-row>
                    <b-row class="custom-margin">
                      <b-col>
                        <span class="plans-row-data-content">
                          {{ lang.pick_up_1 }}
                        </span>
                      </b-col>
                      <b-col>
                        <span class="plans-row-data-content">
                          <i class="ri-arrow-right-fill" />
                        </span>
                      </b-col>
                      <b-col>
                        <span class="plans-row-data-content">
                          {{ lang.drop_1 }}
                        </span>
                      </b-col>
                      <b-col />
                    </b-row>
                    <b-row class="custom-margin">
                      <b-col>
                        <span class="font-color-blue">
                          {{ option.rawData.departure_address }}
                          <br /> 
                          {{ option.pickup_time }}
                        </span>
                      </b-col>
                      <b-col />
                      <b-col>
                        <span class="font-color-blue">
                          {{ option.rawData.arrival_address }}
                          <br />
                          <!-- {{ option.departure_time }} -->
                        </span>
                      </b-col>
                      <b-col />
                    </b-row>
                  </div>
                  <div style="text-align:right;position: absolute;top:0px;right:20px;z-index:9999;">
                    <i :class="option.rawData.bookmark_flg == 1 ? 'ri-bookmark-fill': 'ri-bookmark-line'" :style="option.rawData.bookmark_flg == 1 ? 'color:#FAAB00;cursor:pointer;font-size:30px;' : 'cursor:pointer;font-size:30px;'" @click="update_favorite(option ,index)" />
                  </div>
                </b-col>
              </transition-group>
              <button v-show="activeScroll" style="display:scroll;position:fixed;bottom:10px;right:35px;z-index:999999999999;border:0;background:#ffffff00;" @click="toTop"><img style="width:50px" src="@/assets/images/imgbank/bankscroll.png" /></button>
            </simplebar>
          </b-row>
          <b-row v-else>
            <b-col>
              <br />
              <br />
              <br />
              <br />
              <br />
              <b-col lg="12" class="d-flex justify-content-center">
                <img src="@/assets/images/search.png" height="188px" />
              </b-col>
              <br />
              <b-col lg="12" class="d-flex justify-content-center">
                <span style="font-size: 24px; font-weight: 600;">
                  ไม่พบ "แผนงานขนส่ง" ของคุณ
                </span>
              </b-col>
              <br />
              <b-col lg="12" class="d-flex justify-content-center">
                <button type="button" class="btn btn-primary" @click="routersClick2('/transportation_plan/create')">
                  <!-- <img src="@/assets/images/right_bar_menu/plus (1).svg" height="18px" width="18px" class="icon-side-nav-menu" /> -->
                  <i class="fa fa-plus" aria-hidden="true" />
                  สร้างแผนงาน
                </button>
              </b-col>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    <ModalCompany ref="modalCompany" :obj="{}" />
    <ModalReview ref="modalReview" :obj="{}" @fin-plan="sendFinPlan" />
    <!-- <ModalCompany ref="modalCompany2" :obj="{}" /> -->
    <ModalProof ref="modalProof" :obj="{}" @fin-plan="askFinPlan" />
  </Layout>
</template>
<style>
body {
  overflow: hidden;
}
hr {
  border: 1px solid #e4e4e4;
}
.col-main-menu-tran {
  padding: 0px;
}
.h-100vh {
  height: 100% !important;
}
.plans-row-data {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #b0bac4;
  border-radius: 5px;
  opacity: 1;
  max-height: 230px;
  min-height: 230px;
  padding: 1rem;
}
.plans-row-data:hover {
  transition: 0.3s;
  border: 1px solid #03aeef;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
.plans-col {
  /* transition: all 1s; */
  display: inline-block;
  margin: 0 0 0.5rem 0;
}
.plans-main-display {
  max-height: 77.5vh;
  min-height: 77.5vh;
}
.plans-row-data-title {
  font-size: 24px;
  font-weight: 500;
}
.plans-row-data-content {
  font-size: 16px;
  font-weight: 500;
}
.custom-margin {
  margin-bottom: 0.5rem;
}
.summary-of-plan.active {
  position: absolute;
  width: 100%;
  z-index: 13;
}
.plans_detail_title {
  font-size: 32px;
  font-weight: 500;
}
.avatar-title {
  /* background-color: #fff !important; */
  background-color: hsla(197, 100%, 89%, 0) !important;
}
.col-margin-bot-1 {
  margin-bottom: 0.6rem;
}
.service-provider-display {
  padding: 20px;
}

</style>
