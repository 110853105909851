<script>
import i18n from '@/i18n'
import service from '@/server/http_service'
// import vue2Dropzone from 'vue2-dropzone'
// import global_func from '@/global_function'

/**
 * Starter page component
 */
export default {
  page: {
    // title: 'หน้าว่าง all',
    meta: [{ name: 'description' }],
  },
  components: {
    
  },
  props: {
    obj: {
      type: Object,
      default: () => {
        return { message: 'no obj' }
      },
    },
  },
  data() {
    let lang = localStorage.getItem('account_lang')
    let user = localStorage.getItem('users')
    var lang_obj = i18n.t('upload_file_modal')
    return {
      //genaral
      account_lang: lang,
      account_data: JSON.parse(user),
      lang: lang_obj,
      lang_title: 'ข้อเสนอทั้งหมด',
      lang_sub_title: '',
      // estimate data
      estimate_data : [],
      dataTruckType : [],
    }
  },
  watch: {},
  beforeMount() {},
  mounted() {},
  methods: {
    active(data, data2) {
      var that = this
      that.$bvModal.show('modal_all_estimate')
      // this.setEstimateData(data)
      this.getEstimateData(data)
      this.dataTruckType = data2
    },
    getEstimateData(data) {
      service
        .postService('getEstimateData', {
          offer_data: data,
        })
        .then(rp => {
          // console.log('getEstimateData')
          // console.log(rp)
          this.setEstimateData(rp.data)
        })
    },
    setEstimateData(data) {
      this.estimate_data = []
      this.estimate_data = data
      // console.log('ข้อเสนอทั้งหมด')
      // console.log(this.estimate_data)
    },
    findTruckTypeName(truck_type_id) {
      var truck_type_name = ''
      for (let index = 0; index < this.dataTruckType.length; index++) {
        const element = this.dataTruckType[index]
        if(truck_type_id == element.id_model) {
          if(truck_type_name != '') {
            truck_type_name += ', '
          }
          if(this.account_lang == 'en') {
            truck_type_name += element.name_model_en
          } else {
            truck_type_name += element.name_model_th
          }
          
        }
      }
      return truck_type_name
    },
    openFileTab(param) {
      if (param.filename != null && param.filename != undefined) {
        let file_data = {
          filename: param.filename,
          title: param.title,
          filetype: param.filetype,
        }
        service
          .postService('readFileDataService', {
            fileData: file_data,
          })
          .then(rp => {
            // console.log(rp)
            if(rp.data != '') {
              //var file = require('/../file_upload_dtc_backhaul/images/offer_file/2021_8_6_11_24_36_105.pdf') //pdf jpg
              // var file = require('/../file_upload_dtc_backhaul/images/offer_file/' + param.filename) //pdf jpg
              var file = rp.data
              var pdfWindow = window.open('')
              pdfWindow.document.write(
                '<iframe width=\'100%\' height=\'100%\' src=' + file + '></iframe>',
              )
            }
          })
      }
    },
    openFileTab2(param){
      if(param.filename != null && param.filename != undefined){
        //var file = require('/../file_upload_dtc_backhaul/images/offer_file/2021_8_6_11_24_36_105.pdf') //pdf jpg
        // var file = require('/../file_upload_dtc_backhaul/images/offer_file/'+param.filename) //pdf jpg
        // var file = require('../../../../../../var/vue/file_upload_dtc_backhaul/images/offer_file/'+param.filename) //pdf jpg
        var file = require('/var/vue/file_upload_dtc_backhaul/images/offer_file/'+param.filename) //pdf jpg
        // window.open(file, '_blank')
        // console.log(file)
        if(param.filetype == 'application/pdf') {
          window.open('..' + file.default, '_blank')
        } else {
          window.open('..' + file, '_blank')
        }
      }
    },
  },
}
</script>

<template>
  <div>
    <!-- <span class="font-size-16">ข้อมูลบริษัท : </span> -->
    <!-- <b-link class="font-size-16" href="#" @click="bt_detail">{{ lang.detail }}</b-link> -->
    <!-- <span @click="bt_detail">{{ lang.detail }}</span> -->
    <b-modal id="modal_all_estimate" centered size="xl" :title="lang_title" title-class="font-size-24" hide-footer>
      <div class="modal-body-custom">
        <div v-for="(option,index) in estimate_data" :key="index">
          <b-row style="background: #F7F7F7;" class="col-margin-bot-1">
            <b-col lg="12">
              <div style="padding: 20px;">
                <b-row class="col-margin-bot-1">
                  <b-col lg="12">
                    <span class="font-size-20 font-weight-bold">
                      เสนอครั้งที่ {{ option.num_estimate }}
                    </span>
                    <span>
                      ({{ option.lastmod | moment("dd DD/MM/YYYY HH:mm") }})
                    </span>
                  </b-col>
                </b-row>
                <b-row class="col-margin-bot-1">
                  <b-col lg="6" class="font-size-16 font-weight-bold">
                    <img v-if="option.vehicle_type_group_id == '1'" src="@/assets/images/truck_img/4_wheels.png" width="60px" style="margin-right: 20px" />
                    <img v-else-if="option.vehicle_type_group_id == '2'" src="@/assets/images/truck_img/6_wheels.png" width="60px" style="margin-right: 20px" />
                    <img v-else-if="option.vehicle_type_group_id == '3'" src="@/assets/images/truck_img/10_wheels.png" width="60px" style="margin-right: 20px" />
                    <img v-else-if="option.vehicle_type_group_id == '4'" src="@/assets/images/truck_img/trailer2.png" width="60px" style="margin-right: 20px" />
                    <img v-else-if="option.vehicle_type_group_id == '5'" src="@/assets/images/truck_img/trailer.png" width="60px" style="margin-right: 20px" />
                    <img v-else-if="option.vehicle_type_group_id == '6'" src="@/assets/images/truck_img/tow_truck.png" width="60px" style="margin-right: 20px" />
                    {{ findTruckTypeName(option.vehicle_type_id) }}
                  </b-col>
                  <b-col lg="5" class="font-size-16">
                    <span>
                      เสนอราคา : 
                    </span>
                    <span class="font-size-20 font-weight-bold">
                      {{ option.price }} {{ lang.bath }}
                    </span>
                    <span class="font-color-red">
                    <!-- ( + 15 บาท) -->
                    </span>
                  </b-col>
                </b-row>
                <b-row class="col-margin-bot-1">
                  <b-col lg="6" class="font-size-16">
                    <span>
                      จำนวนรถ : 
                    </span>
                    <span class="font-color-blue">
                      {{ option.number_of_cars }} {{ lang.car }}
                    </span>
                  </b-col>
                  <b-col lg="5" class="font-size-16">
                    ใบเสนอราคา : 
                    <a target="_blank" class="font-color-blue font_hover" style="color:black;" @click="openFileTab(option)"> <u>{{ option.filename }} </u> </a> 
                  </b-col>
                </b-row>
                <b-row class="col-margin-bot-1">
                  <b-col lg="12" class="font-size-16">
                    <span>
                      หมายเหตุ : 
                    </span>
                    <span class="font-color-blue">
                      {{ option.propose }}
                    </span>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
          <b-row v-show="option.reject_flg == 1" style="background: #FFF6E0;" class="col-margin-bot-1">
            <b-col lg="12">
              <div style="padding: 12px;">
                <span class="font-weight-bold">
                  สาเหตุการยื่นข้อเสนอใหม่ : 
                </span>
                <span>
                  {{ option.reject_comment }}
                </span>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<style scoped>
.modal-body-custom {
  padding: 1rem 1rem;
}
.modal-scrollbar {
  overflow: hidden scroll;
}

.modal-scrollbar {
  overflow: auto;
}
hr {
  border: 1px solid #e4e4e4;
}
.vue-dropzone:hover .dropzone-custom-title {
  color: #03aeef;
}

.vue-dropzone > .dz-preview .dz-remove {
  position: absolute;
  z-index: 30;
  color: #fff;
  margin-left: 5px;
  padding: 5px;
  top: inherit;
  bottom: 15px;
  border: 2px #fff solid;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: 800;
  letter-spacing: 1.1px;
  opacity: 0;
}
</style>
